const Home_Info = [
  {
    id: 1,
    text: "New Arrival",
    value: 25,
    icon: "sms",
  },
  {
    id: 2,
    text: "New Guests",
    value: 50,
    icon: "profile-2user",
  },
  {
    id: 3,
    text: "Total Arrival",
    value: 150,
    icon: "route-square",
  },
  {
    id: 4,
    text: "Total Guests",
    value: 250,
    icon: "profile-2user",
  },
];

const Pathway_Info = [
  {
    id: 1,
    text: "Pathway Today",
    value: 25,
    icon: "chart",
  },
  {
    id: 2,
    text: "Flight Today",
    value: 50,
    icon: "airplane",
  },
  {
    id: 3,
    text: "Merged Group",
    value: 150,
    icon: "profile-2user",
  },
];

const Pathway_Page_Data: any[] = [];
Array.from(Array(1).keys()).forEach((e) => {
  Pathway_Page_Data.push({
    id: `${e}-ee`,
    date: `${e + 7} Aug 2023`,
    mergedPathwayData: [],
    pathwayData: [
      {
        key: `${e + 7 + 1} path_data`,
        id: "1",
        pathway: "path 1",
        time: "03:00",
        city: "Cairo",
        adultNum: 4,
        childNum: 2,
        pickUp: "here",
        drop: "there",
        fileNum: "f340",
        agentFileNum: "af330",
        ticketNum: "4 tickets",
        ticketPrice: "300 EGP",
        flightReference: "Loo",
        flightNum: "22:20am",
        pathCity: "4",
        flightDate: "02",
        flightTime: "DBL",
        guideInVisits: "Ali",
        leaderName: "Mohamed",
        leaderFees: "200 EGP",
        driverName: "Mona",
        vehicle: "324 أ ب ي",
        vehicleFees: "400 EGP",
        note: "none",
      },
      {
        key: `${e + 7 + 2} path_data`,
        id: "2",
        pathway: "path 2",
        time: "03:00",
        city: "Cairo",
        adultNum: 4,
        childNum: 2,
        pickUp: "here",
        drop: "there",
        fileNum: "f340",
        agentFileNum: "af330",
        ticketNum: "4 tickets",
        ticketPrice: "300 EGP",
        flightReference: "Loo",
        flightNum: "22:20am",
        pathCity: "4",
        flightDate: "02",
        flightTime: "DBL",
        guideInVisits: "Ali",
        leaderName: "Mohamed",
        leaderFees: "200 EGP",
        driverName: "Mona",
        vehicle: "324 أ ب ي",
        vehicleFees: "400 EGP",
        note: "none",
      },
      {
        key: `${e + 7 + 3} path_data`,
        id: "3",
        pathway: "path 3",
        time: "03:00",
        city: "Cairo",
        adultNum: 4,
        childNum: 2,
        pickUp: "here",
        drop: "there",
        fileNum: "f340",
        agentFileNum: "af330",
        ticketNum: "4 tickets",
        ticketPrice: "300 EGP",
        flightReference: "Loo",
        flightNum: "22:20am",
        pathCity: "4",
        flightDate: "02",
        flightTime: "DBL",
        guideInVisits: "Ali",
        leaderName: "Mohamed",
        leaderFees: "200 EGP",
        driverName: "Mona",
        vehicle: "324 أ ب ي",
        vehicleFees: "400 EGP",
        note: "none",
      },
      {
        key: `${e + 7 + 4} path_data`,
        id: "4",
        pathway: "path 4",
        time: "03:00",
        city: "Cairo",
        adultNum: 4,
        childNum: 2,
        pickUp: "here",
        drop: "there",
        fileNum: "f340",
        agentFileNum: "af330",
        ticketNum: "4 tickets",
        ticketPrice: "300 EGP",
        flightReference: "Loo",
        flightNum: "22:20am",
        pathCity: "4",
        flightDate: "02",
        flightTime: "DBL",
        guideInVisits: "Ali",
        leaderName: "Mohamed",
        leaderFees: "200 EGP",
        driverName: "Mona",
        vehicle: "324 أ ب ي",
        vehicleFees: "400 EGP",
        note: "none",
      },
    ],
  });
});

export default {
  home: Home_Info,
  pathway: Pathway_Info,
  pathwayData: Pathway_Page_Data,
};
