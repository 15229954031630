// appReducer.ts
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface IAppState {
  isNewArrival: any;
  isLoading: boolean;
  deviceId: string | null;
  selectedPathway: string | null; // Add selected pathway state
  startDate: string | null | undefined;
  endDate: string | null | undefined;
  isFiltered: boolean; // Change the type to boolean
  arrivalsCount: number;
}

const initialState: IAppState = {
  isLoading: false,
  deviceId: null,
  selectedPathway: null, // Initialize selected pathway state
  startDate: null,
  endDate: null,
  isNewArrival: false,
  isFiltered: false,
  arrivalsCount: 0,
};

export const appSlice = createSlice({
  name: "app",
  initialState,
  reducers: {
    showLoader: (state) => ({
      ...state,
      isLoading: true,
    }),
    hideLoader: (state) => ({
      ...state,
      isLoading: false,
    }),
    setDeviceId: (state, action: PayloadAction<string>) => ({
      ...state,
      deviceId: action.payload,
    }),
    setSelectedPathway: (state, action: PayloadAction<string | null>) => ({
      ...state,
      selectedPathway: action.payload,
    }),
    setIsNewArrival: (
      state,
      action: PayloadAction<boolean | null | undefined>
    ) => ({
      ...state,
      isNewArrival: action.payload,
    }),
    setStartDate: (
      state,
      action: PayloadAction<string | null | undefined>
    ) => ({
      ...state,
      startDate: action.payload,
    }),
    setEndDate: (state, action: PayloadAction<string | null | undefined>) => ({
      ...state,
      endDate: action.payload,
    }),
    setFiltered: (state, action: PayloadAction<boolean>) => ({
      // Change the payload type to boolean
      ...state,
      isFiltered: action.payload,
    }),
    setArrivalsCount: (state, action: PayloadAction<number>) => ({
      ...state,
      arrivalsCount: action.payload,
    }),
  },
});

// Action creators are generated for each case reducer function
export const {
  showLoader,
  hideLoader,
  setDeviceId,
  setStartDate,
  setEndDate,
  setFiltered,
  setIsNewArrival,
  setSelectedPathway,
  setArrivalsCount,
} = appSlice.actions;

export default appSlice.reducer;
