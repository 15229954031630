import Button from "components/general/Button";
import Image from "components/general/Image";
import FileDocumentImg from "assets/images/document-text.svg";
import styles from "./styles.module.scss";

export default function FileAttachment({ text }: { text: string }) {
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        columnGap: "8px",
      }}
    >
      <Button btnClassName={`${styles.fileDocumentStyle}`} isOutline>
        <Image src={FileDocumentImg} width={24} alt="file" />
      </Button>
      <span>{text}</span>
    </div>
  );
}
