import CheckBox from "components/general/CheckBox";
import useToggleState from "hooks/useToggleState";
import { useSearchParams } from "react-router-dom";
import { Dropdown, Space } from "antd";
import { useEffect } from "react";
import { parseParam, stringifyParam } from "utils/queryParamService";
import styles from "./styles.module.scss";
import ModalWrapper from "../ModalWrapper";
import { FilterSelectionModalProps } from "./types";

export default function FilterDropDownModal({
  data,
  filterType,
  isVisible = false,
  setIsVisible,
}: FilterSelectionModalProps) {
  const [searchParams, setSearchParams] = useSearchParams();
  const {
    selectedValues: selectedFilterValues,
    addToSelected: addToFilter,
    replaceState: replaceFilterWith,
    isSelected: isAlreadyInFilter,
  } = useToggleState<string>([]);

  const onChangeValueHandler = (value: string) => {
    addToFilter(value);
  };

  useEffect(() => {
    const defaultFilterValues = searchParams.get(filterType);
    if (defaultFilterValues) {
      const parsedDefaultFilterValues = parseParam(defaultFilterValues);
      replaceFilterWith(parsedDefaultFilterValues);
    }
  }, [isVisible]);

  const onConfirmHandler = () => {
    searchParams.set(filterType, stringifyParam(selectedFilterValues));

    setSearchParams(searchParams);

    setIsVisible(false);
  };

  return (
    <ModalWrapper
      setIsVisible={setIsVisible}
      size="small"
      headerTitle={`Filter by ${filterType}`}
      headerTitleJustify="start"
      isVisible={isVisible}
      onConfirm={onConfirmHandler}
    >
      <div className={`row d-flex ${styles.Maincontainer}`}>
        {data?.map((item) => (
          <div className={`col-9 ${styles.container}`}>
            <CheckBox
              text={item.label}
              defaultChecked={isAlreadyInFilter(item.key)}
              onValueChange={() => {
                onChangeValueHandler(item.key);
              }}
            />
          </div>
        ))}
      </div>
    </ModalWrapper>
  );
}
