import styles from "./styles.module.scss";
import { BorderedWhiteBtnProps } from "./types";

export default function BorderedWhiteBtn({
  containerStyle,
  children,
  onClick,
}: BorderedWhiteBtnProps) {
  return (
    <button
      className={`${styles.borderedBtn} ${containerStyle}`}
      onClick={onClick}
    >
      {children}
    </button>
  );
}
