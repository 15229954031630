import { ChangeEventHandler, useMemo, useState } from "react";
import { debounce } from "lodash-es";
import { DebouncedSearchProps } from "./types";
import TextInput from "../TextInput";

export default function DebouncedSearch({
  defaultValue = "",
  onSearchChange,
  ...props
}: DebouncedSearchProps) {
  const [searchValue, setsearchValue] = useState(defaultValue);

  const debouncedSearchHandler = useMemo(
    () => debounce(onSearchChange, 500),
    [onSearchChange]
  );

  const onChangeHandler: ChangeEventHandler<HTMLInputElement> = (e) => {
    setsearchValue(e.target.value);
    debouncedSearchHandler(e.target.value);
  };

  return (
    <TextInput {...props} value={searchValue} onChange={onChangeHandler} />
  );
}
