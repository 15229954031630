import { ColumnTypes } from "components/TableForm/types";

export const columnsForFlight: (ColumnTypes[number] & {
  editable?: boolean;
  dataIndex: string;
})[] = [
  {
    title: "Flight",
    dataIndex: "flight",
    width: 188,
    editable: true,
  },
  {
    title: "Date",
    dataIndex: "date",
    width: 188,
    editable: false,
  },
  {
    title: "Flight Num.",
    dataIndex: "flightNum",
    width: 188,
    editable: true,
  },
  {
    title: "Names",
    dataIndex: "names",
    width: 188,
    editable: false,
  },

  {
    title: "From",
    dataIndex: "from",
    width: 188,
    editable: false,
  },
  {
    title: "To",
    dataIndex: "to",
    width: 188,
    editable: false,
  },
  {
    title: "Departure Time",
    dataIndex: "departureTime",
    width: 188,
    editable: false,
  },
  {
    title: "Arrival Time",
    dataIndex: "arrivalTime",
    width: 188,
    editable: false,
  },
  {
    title: "To/From",
    dataIndex: "pickUp",
    width: 188,
    editable: true,
  },
  {
    title: "File Num.",
    dataIndex: "fileNum",
    width: 188,
    editable: false,
    onCell: (record) => {
      return {
        onClick: () => {
          window.location.href = `reservation-details?id=${record.arrival.id}`;
        },
        className: "clickable-cell",
      };
    },
  },
  {
    title: "Agent File Num.",
    dataIndex: "agentFileNum",
    width: 188,
    editable: false,
  },
  {
    title: "Guide In Visits",
    dataIndex: "guideInVisits",
    width: 188,
    editable: true,
  },
  {
    title: "Guide Fees",
    dataIndex: "guideFees",
    width: 188,
    editable: false,
  },
  {
    title: "Leader Name",
    dataIndex: "leaderName",
    width: 188,
    editable: true,
  },
  {
    title: "Leader Fees",
    dataIndex: "leaderFees",
    width: 188,
    editable: false,
  },
  {
    title: "Driver Name",
    dataIndex: "driverName",
    width: 188,
    editable: true,
  },
  {
    title: "Vehicle",
    dataIndex: "vehicle",
    width: 188,
    editable: false,
  },
  {
    title: "Vehicle Fees",
    dataIndex: "vehicleFees",
    width: 188,
    editable: false,
  },
  {
    title: "Visa",
    dataIndex: "visa",
    width: 188,
    editable: true,
  },
  {
    title: "Visa Fees",
    dataIndex: "visaFees",
    width: 188,
    editable: false,
  },
  {
    title: "Note",
    dataIndex: "note",
    width: 188,
    editable: true,
  },
];

export const columnsForTrip: (ColumnTypes[number] & {
  editable?: boolean;
  dataIndex: string;
})[] = [
  {
    title: "Pathway",
    dataIndex: "pathway",
    width: 188,
    editable: true,
  },
  {
    title: "Trip type",
    dataIndex: "trip_type",
    width: 188,
    editable: true,
  },
  {
    title: "Date",
    dataIndex: "date",
    width: 188,
    editable: true,
  },
  {
    title: "Time",
    dataIndex: "time",
    width: 188,
    editable: true,
  },
  {
    title: "City",
    dataIndex: "city",
    width: 188,
    editable: true,
  },
  {
    title: "No. of Adult",
    dataIndex: "adultNum",
    width: 188,
    editable: true,
  },
  {
    title: "No. of Child",
    dataIndex: "childNum",
    width: 188,
    editable: true,
  },
  {
    title: "Pick up From",
    dataIndex: "pickUp",
    width: 188,
    editable: true,
  },
  {
    title: "Drop To",
    dataIndex: "dropTo",
    width: 188,
    editable: true,
  },
  {
    title: "File Num.",
    dataIndex: "fileNum",
    width: 188,
    editable: false,
    onCell: (record) => {
      return {
        onClick: () => {
          window.location.href = `reservation-details?id=${record.arrival.id}`;
        },
        className: "clickable-cell",
      };
    },
  },
  {
    title: "Agent File Num.",
    dataIndex: "agentFileNum",
    width: 188,
    editable: false,
  },
  {
    title: "Ticket Price",
    dataIndex: "ticketPrice",
    width: 188,
    editable: false,
  },
  {
    title: "Guide In Visits",
    dataIndex: "guideInVisits",
    width: 188,
    editable: true,
  },
  {
    title: "Guide Fees",
    dataIndex: "guideFees",
    width: 188,
    editable: false,
  },
  {
    title: "Leader Name",
    dataIndex: "leaderName",
    width: 188,
    editable: true,
  },
  {
    title: "Leader Fees",
    dataIndex: "leaderFees",
    width: 188,
    editable: false,
  },
  {
    title: "Driver Name",
    dataIndex: "driverName",
    width: 188,
    editable: true,
  },
  {
    title: "Vehicle",
    dataIndex: "vehicle",
    width: 188,
    editable: false,
  },
  {
    title: "Vehicle Fees",
    dataIndex: "vehicleFees",
    width: 188,
    editable: false,
  },
  {
    title: "Note",
    dataIndex: "note",
    width: 188,
    editable: true,
  },
];

export const columnsForMixedMerged: (ColumnTypes[number] & {
  editable?: boolean;
  dataIndex: string;
})[] = [
  {
    title: "Flight/Trip",
    dataIndex: "flight",
    width: 188,
    editable: true,
  },
  {
    title: "Date",
    dataIndex: "date",
    width: 188,
    editable: false,
  },
  {
    title: "Time",
    dataIndex: "time",
    width: 188,
    editable: true,
  },
  {
    title: "City",
    dataIndex: "city",
    width: 188,
    editable: true,
  },
  {
    title: "No. of Adult",
    dataIndex: "adultNum",
    width: 188,
    editable: true,
  },
  {
    title: "No. of Child",
    dataIndex: "childNum",
    width: 188,
    editable: true,
  },
  {
    title: "Pick up From - To/From",
    dataIndex: "pickUp",
    width: 175,
    editable: true,
  },
  {
    title: "Drop To",
    dataIndex: "dropTo",
    width: 188,
    editable: true,
  },
  {
    title: "Flight Num.",
    dataIndex: "flightNum",
    width: 188,
    editable: true,
  },
  {
    title: "Names",
    dataIndex: "names",
    width: 188,
    editable: false,
  },

  {
    title: "From",
    dataIndex: "from",
    width: 188,
    editable: false,
  },
  {
    title: "To",
    dataIndex: "to",
    width: 188,
    editable: false,
  },
  {
    title: "Departure Time",
    dataIndex: "departureTime",
    width: 188,
    editable: false,
  },
  {
    title: "Arrival Time",
    dataIndex: "arrivalTime",
    width: 188,
    editable: false,
  },
  {
    title: "Ticket Price",
    dataIndex: "ticketPrice",
    width: 188,
    editable: false,
  },
  {
    title: "File Num.",
    dataIndex: "fileNum",
    width: 188,
    editable: false,
    onCell: (record) => {
      return {
        onClick: () => {
          window.location.href = `reservation-details?id=${record.arrival.id}`;
        },
        className: "clickable-cell",
      };
    },
  },
  {
    title: "Agent File Num.",
    dataIndex: "agentFileNum",
    width: 188,
    editable: false,
  },
  {
    title: "Guide In Visits",
    dataIndex: "guideInVisits",
    width: 188,
    editable: true,
  },
  {
    title: "Guide Fees",
    dataIndex: "guideFees",
    width: 188,
    editable: false,
  },
  {
    title: "Leader Name",
    dataIndex: "leaderName",
    width: 188,
    editable: true,
  },
  {
    title: "Leader Fees",
    dataIndex: "leaderFees",
    width: 188,
    editable: false,
  },
  {
    title: "Driver Name",
    dataIndex: "driverName",
    width: 188,
    editable: true,
  },
  {
    title: "Vehicle",
    dataIndex: "vehicle",
    width: 188,
    editable: false,
  },
  {
    title: "Driver Fees",
    dataIndex: "driverFees",
    width: 188,
    editable: false,
  },
  {
    title: "Car Fees",
    dataIndex: "vehicleFees",
    width: 188,
    editable: false,
  },
  {
    title: "Visa",
    dataIndex: "visa",
    width: 188,
    editable: true,
  },
  {
    title: "Visa Fees",
    dataIndex: "visaFees",
    width: 188,
    editable: false,
  },
  {
    title: "Note",
    dataIndex: "note",
    width: 188,
    editable: true,
  },
];
