import { Link, useLocation, useNavigate } from "react-router-dom";
import { Form } from "antd";
import TextInput from "components/inputs/TextInput";
import Button from "components/general/Button";
import loginHandler from "utils/loginHandler";
import { useLoginMutation } from "apis/services/auth";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import handleErrors from "utils/handleErrors";
import styles from "./styles.module.scss";

const schema = yup
  .object({
    email: yup
      .string()
      .email("Invalid email format")
      .required("Email is required"),
    password: yup.string().required("Password is required"),
  })
  .required();

type FormData = yup.InferType<typeof schema>;
export default function Login() {
  const navigate = useNavigate();
  const location = useLocation();
  const [login, { isLoading }] = useLoginMutation();

  // const onLoginHandler = (values: any) => {
  //   // console.log(values);

  //   loginHandler({
  //     token: "token",
  //     refreshToken: "refresh",
  //   });
  // };
  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm<FormData>({
    resolver: yupResolver(schema),
    mode: "all" /**
     *react-hook-form	validation strategy before submitting behaviour: onChange | onBlur | onSubmit | onTouched | all.
     * I Choose all because the submit btn is disabled as per design it gets enabled when the two fields are validated, so the errors must show to guide user to enable the submit btn
     */,
  });
  const onLoginHandler = (data: FormData) => {
    login(data)
      .unwrap()
      .then((tokens) => {
        loginHandler({
          token: tokens.access_token,
          refreshToken: tokens.refresh_token,
        });
      })
      .finally(() => {
        if (location.state?.from && location.state?.from?.pathname !== "/") {
          navigate(location.state?.from);
        } else {
          navigate("/Dashboard");
        }
      })
      .catch(handleErrors);
  };

  return (
    <>
      <h1 className={styles.title}>Welcome Back!</h1>
      <div className="bodyXL">Login to continue</div>
      <Form
        className={styles.form}
        autoComplete="off"
        autoCorrect="off"
        onFinish={onLoginHandler}
      >
        <Form.Item name="username" rules={[{ required: true }]}>
          <TextInput i18nLabelKey="USER_NAME" />
        </Form.Item>

        <Form.Item
          name="password"
          rules={[
            {
              required: true,
              message: "This Field Is Required",
            },
            {
              pattern: /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)[^\s]{8,}$/g,
              message:
                " Minimum 8 characters. At least one uppercase letter. One lowercase letter and one number.",
            },
          ]}
        >
          <TextInput i18nLabelKey="PASSWORD" type="password" />
        </Form.Item>

        <Link to="/forgot-password" style={{ color: "#000" }}>
          <h5>Forget password?</h5>
        </Link>

        <Button btnClassName={styles.submitBtn} type="submit">
          Log in
        </Button>
      </Form>
    </>
  );
}
