import { Action, isRejectedWithValue } from "@reduxjs/toolkit";
import store from "reducers";
import HandleErrors from "utils/handleErrors";
import logoutHandler from "utils/logoutHandler";

type NextFunction = (action: Action) => void;

const rtkQueryErrorLogger = () => (next: NextFunction) => (action: Action) => {
  // @ts-ignore
  if (action?.payload?.data?.code?.includes("token_not_valid")) {
    logoutHandler();
  } else if (isRejectedWithValue(action)) {
    // @ts-ignore
    HandleErrors(action?.payload?.data);
  }

  return next(action);
};

export default rtkQueryErrorLogger;
