import api from "apis";
import { PaginatedResponse } from "apis/types/general";
import getSerializedQueryArgs from "utils/getSerializedQueryArgs";
import infintyPaginationMergeHandler from "utils/infintyPaginationMergeHandler";
import {
  ArrivalData,
  PostMemberData,
  GetMemberData,
  GetNationalityData,
  GetFlightData,
  GetCitiesData,
  PostFlightData,
  AccommodationType,
  PostArrivalData,
  GetArrivalData,
  Calculation,
  ArrivalParams,
  PostAttachmentData,
} from "../types/arrival";

export const arrivalApi = api.injectEndpoints({
  endpoints: (build) => ({
    arrivalData: build.mutation<ArrivalData, void>({
      query: (body) => ({
        url: `/arrival/`,
        method: "POST",
        body,
      }),
    }),
    postMemberData: build.mutation<PostMemberData, void>({
      query: (body) => ({
        url: `/member/`,
        method: "POST",
        body,
      }),
    }),
    getMemberData: build.mutation<GetMemberData, any>({
      query: (params) => ({
        url: `/member/`,
        method: "GET",
        params,
      }),
    }),
    deleteMemberData: build.mutation<void, any>({
      query: (id) => ({
        url: `/member/${id}?page_size=10000`,
        method: "DELETE",
      }),
    }),

    updateMember: build.mutation<
      void,
      {
        id: number;
        data: PostMemberData;
      }
    >({
      query: (params) => ({
        url: `/member/${params.id}/`,
        method: "PATCH",
        body: params.data,
      }),
      invalidatesTags: ["member"],
    }),

    getSingleMember: build.mutation<any, number>({
      query: (id) => ({
        url: `/member/${id}?page_size=10000`,
        method: "GET",
      }),
      invalidatesTags: ["member"],
    }),

    getNationalityData: build.mutation<GetNationalityData, void>({
      query: () => ({
        url: `/nationality/`,
        method: "GET",
      }),
    }),
    getFlightData: build.mutation<GetFlightData, any>({
      query: (params) => ({
        url: `/flight/`,
        method: "GET",
        params,
      }),
    }),

    updateFlight: build.mutation<
      void,
      {
        id: number;
        data: PostFlightData;
      }
    >({
      query: (params) => ({
        url: `/flight/${params.id}/`,
        method: "PATCH",
        body: params.data,
      }),
      invalidatesTags: ["flight"],
    }),

    getCurrenciesData: build.mutation<any, void>({
      query: () => ({
        url: `/currencies?/`,
        method: "GET",
      }),
    }),
    postFlightData: build.mutation<PostFlightData, void>({
      query: (body) => ({
        url: `/flight/`,
        method: "POST",
        body,
      }),
    }),
    getSingleFlight: build.mutation<void, number>({
      query: (id) => ({
        url: `/flight/${id}?page_size=10000`,
        method: "GET",
      }),
      invalidatesTags: ["flight"],
    }),

    deleteFlightData: build.mutation<void, any>({
      query: (id) => ({
        url: `/flight/${id}`,
        method: "DELETE",
      }),
    }),
    retrieveFilghtData: build.query<any, any>({
      query: (params) => ({
        url: `/flight/`,
        method: "GET",
        params,
      }),
      serializeQueryArgs: getSerializedQueryArgs,
      forceRefetch({ currentArg, previousArg }) {
        return currentArg?.page !== previousArg?.page;
      },
      merge: infintyPaginationMergeHandler,
      providesTags: ["flight"],
    }),
    getCitiesData: build.mutation<PaginatedResponse<GetCitiesData>, void>({
      query: () => ({
        url: `/cities/?page_size=10000`,
        method: "GET",
      }),
    }),
    getAccommodationData: build.query<
      PaginatedResponse<AccommodationType>,
      any | void
    >({
      query: (params) => ({
        url: `/accommodation/${
          params?.arrivalId ? `?arrivalId=${params?.arrivalId}` : ""
        }`,
        method: "GET",
        params,
      }),
      providesTags: (result, error, arrivalId) => [
        { type: "accommodation", id: arrivalId ? arrivalId.toString() : "all" },
      ],
    }),
    getSingleAccommodation: build.mutation<void, number>({
      query: (id) => ({
        url: `/accommodation/${id}?page_size=10000`,
        method: "GET",
      }),
      invalidatesTags: ["accommodation"],
    }),

    createAccommodation: build.mutation<
      AccommodationType,
      Omit<AccommodationType, "check_out" | "total_cost">
    >({
      query: (body) => ({
        url: `/accommodation/`,
        method: "POST",
        body,
      }),
      invalidatesTags: ["accommodation"],
    }),
    deleteAccommodation: build.mutation<void, number>({
      query: (id) => ({
        url: `/accommodation/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["accommodation"],
    }),
    updateAccommodation: build.mutation<
      void,
      {
        id: number;
        data: AccommodationType;
      }
    >({
      query: (params) => ({
        url: `/accommodation/${params.id}/`,
        method: "PATCH",
        body: params.data,
      }),
      invalidatesTags: ["accommodation"],
    }),
    postArrivalData: build.mutation<PostArrivalData, any>({
      query: (body) => ({
        url: `/arrival/`,
        method: "POST",
        body,
      }),
    }),
    getPathwayData: build.mutation<any, void>({
      query: () => ({
        url: `/traffic/`,
        method: "GET",
      }),
    }),
    getSinglePathway: build.mutation<any, number>({
      query: (id) => ({
        url: `/traffic/${id}/`,
        method: "GET",
      }),
      invalidatesTags: ["traffic"],
    }),

    updatePathway: build.mutation<
      void,
      {
        id: number;
        data: any;
      }
    >({
      query: (params) => ({
        url: `/traffic/${params.id}/`,
        method: "PATCH",
        body: params.data,
      }),
      invalidatesTags: ["pathway"],
    }),

    deletePathwayData: build.mutation<void, any>({
      query: (id) => ({
        url: `/traffic/${id}/`,
        method: "DELETE",
      }),
    }),
    getArrivalData: build.mutation<GetArrivalData, ArrivalParams>({
      query: (params) => ({
        url: `/arrival/`,
        method: "GET",
        params,
      }),
    }),
    getCalculation: build.mutation<Calculation, any>({
      query: (params) => ({
        url: `arrival/get_calculation/`,
        method: "GET",
        params,
      }),
    }),
    getArrivalDataById: build.mutation<any, any>({
      query: (id) => ({
        url: `/arrival/${id}?page_size=10000`,
        method: "GET",
      }),
      invalidatesTags: ["arrival"],
    }),
    updateArrivalData: build.mutation<
      void,
      {
        id: number;
        data: any;
      }
    >({
      query: (params) => ({
        url: `/arrival/${params.id}/`,
        method: "PATCH",
        body: params.data,
      }),
      invalidatesTags: ["arrival"],
    }),

    getSingleAttachmentData: build.mutation<void, number>({
      query: (id) => ({
        url: `/attachment/${id}`,
        method: "GET",
      }),
      invalidatesTags: ["attachment"],
    }),

    getHotels: build.mutation<any, any>({
      query: (params) => ({
        url: `/hotel/?page_size=10000`,
        method: "GET",
        params,
      }),
    }),

    getAccommodationReport: build.mutation<any, any>({
      query: (params) => ({
        url: `/accommodation/report/?hotel_cruise=${params.id}&check_in_before=${params.start}&check_in_after=${params.end}`,
        method: "GET",
      }),
    }),
    getAllAccommodation: build.mutation<any, any>({
      query: (params) => ({
        url: `/accommodation/?page_size=10000`,
        method: "GET",
        params,
      }),
    }),
    getTrafficData: build.query<any, any>({
      query: (params) => ({
        url: `/traffic/`,
        method: "GET",
        params,
      }),
      forceRefetch({ currentArg, previousArg }) {
        return currentArg?.page !== previousArg?.page;
      },
      merge: infintyPaginationMergeHandler,
      providesTags: ["Traffic"],
    }),

    postAttachmentData: build.mutation<PostAttachmentData, [body: FormData]>({
      query: ([body]) => ({
        url: `/attachment/`,
        method: "POST",
        body,
      }),
      invalidatesTags: ["attachment"],
    }),

    deleteAttachment: build.mutation<void, number>({
      query: (id) => ({
        url: `/attachment/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["attachment"],
    }),

    getArrivalExcelData: build.mutation<any, void>({
      query: () => ({
        url: `/arrival/export_data/`,
        method: "GET",
      }),
    }),

    postTrafficData: build.mutation<any, any>({
      query: (body) => ({
        url: `/traffic/`,
        method: "POST",
        body,
      }),
      invalidatesTags: ["Traffic"],
    }),

    postTrafficMergeData: build.mutation<any, any>({
      query: (body) => ({
        url: `/traffic/merge_traffics/`,
        method: "POST",
        body,
      }),
      invalidatesTags: ["Traffic"],
    }),

    getTrafficName: build.query<any, any>({
      query: (params) => ({
        url: `/traffic-name/`,
        method: "GET",
        params,
      }),
      serializeQueryArgs: getSerializedQueryArgs,
      forceRefetch({ currentArg, previousArg }) {
        return currentArg?.page !== previousArg?.page;
      },
      merge: infintyPaginationMergeHandler,
      providesTags: ["Traffic_Name"],
    }),

    getPathwayCalculation: build.mutation<any, any>({
      query: (params) => ({
        url: `/traffic/get_pathway_calculations/`,
        method: "GET",
        params,
      }),

      invalidatesTags: ["Traffic_Name"],
    }),

    getPathwayNameReport: build.mutation<any, any>({
      query: (params) => ({
        url: `/traffic-name/get_traffic_name_report/`,
        method: "GET",
        params,
      }),
    }),

    getVehicleReport: build.mutation<any, any>({
      query: (params) => ({
        url: `/vehicle/get_vehicle_report/`,
        method: "GET",
        params,
      }),
    }),

    getVehicle: build.query<any, any>({
      query: (params) => ({
        url: `/vehicle/`,
        method: "GET",
        params,
      }),
      serializeQueryArgs: getSerializedQueryArgs,
      forceRefetch({ currentArg, previousArg }) {
        return currentArg?.page !== previousArg?.page;
      },
      merge: infintyPaginationMergeHandler,
      providesTags: ["Vehicle"],
    }),

    getUser: build.query<any, any>({
      query: (params) => ({
        url: `/user-list/`,
        method: "GET",
        params,
      }),
      serializeQueryArgs: getSerializedQueryArgs,
      forceRefetch({ currentArg, previousArg }) {
        return currentArg?.page !== previousArg?.page;
      },
      merge: infintyPaginationMergeHandler,
      providesTags: ["Users_Roles"],
    }),
  }),
});

export const {
  useArrivalDataMutation,
  usePostMemberDataMutation,
  useGetMemberDataMutation,
  useDeleteMemberDataMutation,
  useUpdateMemberMutation,
  useGetSingleMemberMutation,
  useGetNationalityDataMutation,
  useGetFlightDataMutation,
  useUpdateFlightMutation,
  useGetCitiesDataMutation,
  usePostFlightDataMutation,
  useGetSingleFlightMutation,
  useDeleteFlightDataMutation,
  useGetAccommodationDataQuery,
  useGetSingleAccommodationMutation,
  useLazyGetAccommodationDataQuery,
  useCreateAccommodationMutation,
  useDeleteAccommodationMutation,
  useUpdateAccommodationMutation,
  useGetCurrenciesDataMutation,
  usePostArrivalDataMutation,
  useGetPathwayDataMutation,
  useGetArrivalDataMutation,
  useGetCalculationMutation,
  useGetArrivalDataByIdMutation,
  useUpdateArrivalDataMutation,
  useGetSingleAttachmentDataMutation,
  useGetHotelsMutation,
  useGetAccommodationReportMutation,
  useGetAllAccommodationMutation,
  useGetTrafficDataQuery,
  useDeletePathwayDataMutation,
  useLazyGetTrafficDataQuery,
  useGetSinglePathwayMutation,
  useUpdatePathwayMutation,
  usePostAttachmentDataMutation,
  useDeleteAttachmentMutation,
  useGetArrivalExcelDataMutation,
  usePostTrafficDataMutation,
  usePostTrafficMergeDataMutation,
  useGetPathwayCalculationMutation,
  useRetrieveFilghtDataQuery,
  useLazyRetrieveFilghtDataQuery,
  useGetTrafficNameQuery,
  useLazyGetTrafficNameQuery,
  useGetVehicleQuery,
  useLazyGetVehicleQuery,
  useGetUserQuery,
  useLazyGetUserQuery,
  useGetPathwayNameReportMutation,
  useGetVehicleReportMutation,
} = arrivalApi;
