import { useState, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import {
  useGetArrivalDataByIdMutation,
  useGetFlightDataMutation,
  useGetSingleFlightMutation,
} from "apis/services/arrival";
import TableForm from "components/TableForm";
import TableMockup from "./TableMockup";
import { ColumnTypes } from "./types";

const columns: (ColumnTypes[number] & {
  editable?: boolean;
  dataIndex: string;
})[] = [
  {
    title: "",
    dataIndex: "empty",
    key: "empty",
    width: 20,
    fixed: "left",
  },
  {
    title: "Flight Reference",
    dataIndex: "flight_reference",
    width: 188,
    editable: true,
  },
  {
    title: "Name",
    dataIndex: "member",
    width: 188,
    editable: true,
  },
  {
    title: "Date",
    dataIndex: "date",
    width: 188,
    editable: true,
  },
  {
    title: "Flight Num.",
    dataIndex: "flight_number",
    width: 188,
    editable: true,
  },
  {
    title: "From",
    dataIndex: "flightFrom",
    width: 188,
    editable: true,
  },
  {
    title: "To",
    dataIndex: "flightTo",
    width: 188,
    editable: true,
  },
  {
    title: "Departure Time",
    dataIndex: "departure_time",
    width: 188,
    editable: true,
  },
  {
    title: "Arrival Time",
    dataIndex: "arrival_time",
    width: 188,
    editable: true,
  },
];

type FlightData = any;
type FlightsTableProps = {
  id: any;
};

const getEmptyObj = (key: number) => {
  return {
    key: key.toString(),
    flight_reference: ``,
    member: ``,
    date: "",
    flight_number: "",
    flightFrom: "",
    flightTo: "",
    departure_time: "",
    arrival_time: "",
  };
};

export default function Flight({ id }: FlightsTableProps) {
  const [editingKey, setEditingKey] = useState("");
  const [flightData, setFlightData] = useState<FlightData>([]);
  const [paginationCount, setPaginationCount] = useState(0);
  const [searchParams] = useSearchParams();
  const [getFlightData] = useGetFlightDataMutation();
  const [getArrivalData, { isLoading: isGetArrivalDataLoading }] =
    useGetArrivalDataByIdMutation();

  const handleAddNewRow = () => {
    const newRow = getEmptyObj(flightData.length + 1);
    setFlightData((prev: any) => [newRow, ...prev]);
    setEditingKey(`${flightData.length + 1}`);
  };

  const urlId = id;

  const formatTime = (timeStr: string) => {
    const [hours, minutes, seconds] = timeStr.split(":");
    const hour = parseInt(hours, 10);
    const minute = parseInt(minutes, 10);
    const isPM = hour >= 12;
    const formattedHour = hour % 12 || 12; // Convert 0 to 12
    const formattedMinutes = minute.toString().padStart(2, "0");
    const period = isPM ? "pm" : "am";
    return `${formattedHour}:${formattedMinutes} ${period}`;
  };

  const formatDate = (dateStr: string) => {
    const dateParts = dateStr.split("-");
    const year = dateParts[0];
    const month = new Date(`${dateStr}T00:00:00`).toLocaleString("en-US", {
      month: "long",
    });
    const day = dateParts[2];
    return `${day} ${month} ${year}`;
  };

  const fetchFlightData = async (param: any) => {
    try {
      const arrivalData = await getArrivalData(id).unwrap();
      getFlightData(param)
        .unwrap()
        .then((flightData: any) => {
          setPaginationCount(flightData.count);

          const formattedFlightsData = flightData?.results?.map(
            (flight: any) => ({
              key: flight?.id,
              flight_reference: flight?.flight_reference,
              member: flight?.member?.name,
              date: formatDate(flight?.date),
              flight_number: flight?.flight_number,
              flightFrom: flight?.departure && flight?.departure.name,
              flightTo: flight?.destination && flight?.destination.name,
              departure_time: formatTime(flight.departure_time),
              arrival_time: formatTime(flight.arrival_time),
            })
          );
          setFlightData(formattedFlightsData);
        });
    } catch (error) {
      console.error("Error fetching flight data:", error);
    }
  };

  useEffect(() => {
    fetchFlightData({
      page: searchParams.get("flightTable") ?? "1",
      page_size: 5,
      arrival: id,
    });
  }, [id, searchParams.get("flightTable")]);

  return (
    <div>
      <TableMockup
        title="Flight"
        btn1Title="Add New Flight"
        onClickBtn1={handleAddNewRow}
        isBtnDisabled={!!editingKey || isGetArrivalDataLoading}
        handleCheck=""
      >
        <TableForm
          data={flightData}
          setData={setFlightData}
          columns={columns}
          editingKey={editingKey}
          setEditingKey={setEditingKey}
          tourPathMethod={() => {}}
          title="flights"
          fetchData={fetchFlightData}
          urlId={urlId}
          setIsLoading={isGetArrivalDataLoading}
          paginationCount={paginationCount}
          paginationName="flightTable"
        />
      </TableMockup>
    </div>
  );
}
