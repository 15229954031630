import type { ColumnsType } from "antd/es/table";
import CircleImg from "components/CircleImg";
import MultibleCircleImg from "components/MultibleCircleImgs";
import ProfileImg from "assets/images/bw.png";
import FileAttachment from "components/FileAttachment";
import { TableData } from "./types";

export const columns: ColumnsType<TableData> = [
  {
    title: "",
    dataIndex: "empty",
    key: "empty",
    width: 20,
    fixed: "left",
  },
  {
    title: "Arrival Date",
    dataIndex: "arrivalDate",
    key: "arrivalDate",
    width: 150,
  },
  {
    title: "Leave Date",
    dataIndex: "leaveDate",
    key: "leaveDate",
    width: 150,
  },
  {
    title: "File Num.",
    dataIndex: "fileNum",
    key: "fileNum",
    width: 150,
  },
  {
    title: "Agent File Num.",
    dataIndex: "agentFileNum",
    key: "agentFileNum",
    width: 150,
  },
  {
    title: "Guest Name",
    dataIndex: "clientName",
    key: "clientName",
    width: 150,
  },
  {
    title: "Adults",
    dataIndex: "noAdultGuest",
    key: "noAdultGuest",
    width: 150,
  },
  {
    title: "Children",
    dataIndex: "noChildGuest",
    key: "noChildGuest",
    width: 150,
  },
  {
    title: "Flight",
    children: [
      {
        title: "Flight Reference",
        dataIndex: "flightReference",
        key: "flightReference",
        width: 150,
      },
      {
        title: "Date",
        dataIndex: "date",
        key: "date",
        width: 150,
      },
      {
        title: "Flight Num.",
        dataIndex: "flightNum",
        key: "flightNum",
        width: 150,
      },
      {
        title: "From",
        dataIndex: "from",
        key: "from",
        width: 150,
      },
      {
        title: "To",
        dataIndex: "to",
        key: "to",
        width: 150,
      },
      {
        title: "Departure Time",
        dataIndex: "departureTime",
        key: "departureTime",
        width: 150,
      },
      {
        title: "Arrival Time",
        dataIndex: "arrivalTime",
        key: "arrivalTime",
        width: 150,
      },
    ],
  },
  // {
  //   title: "Creator",
  //   dataIndex: "creator",
  //   key: "creator",
  //   width: 150,
  //   render: (text) => {
  //     return (
  //       <div
  //         style={{
  //           display: "flex",
  //           columnGap: 10,
  //         }}
  //       >
  //         <CircleImg size={30} src={ProfileImg} />
  //         <span>{text}</span>
  //       </div>
  //     );
  //   },
  // },
  // {
  //   title: "Seen",
  //   dataIndex: "seen",
  //   key: "seen",
  //   width: 190,
  //   render: (text) => {
  //     return (
  //       <div
  //         style={{
  //           display: "flex",
  //           columnGap: 10,
  //           alignItems: "center",
  //         }}
  //       >
  //         <MultibleCircleImg
  //           data={[
  //             { src: ProfileImg },
  //             { src: ProfileImg },
  //             { src: ProfileImg },
  //           ]}
  //         />
  //         <span>{text}</span>
  //       </div>
  //     );
  //   },
  // },
  {
    title: "Attachments",
    dataIndex: "attachments",
    key: "attachments",
    width: 170,
    // render: (text) => {
    //   return <FileAttachment text={text} />;
    // },
  },
  // {
  //   title: "Note",
  //   dataIndex: "note",
  //   key: "note",
  //   width: 150,
  // },
];

const tableData: TableData[] = [];

Array.from(Array(20).keys()).forEach((e) => {
  tableData.push({
    key: `${e + 1}`,
    empty: "",
    arrivalDate: "01 Jun 2023",
    leaveDate: "01 Aug 2023",
    agentFileNum: "NT 21394",
    clientName: "Hans Henry",
    fileNum: `${e + 1}`,
    noAdultGuest: "30",
    noChildGuest: "20",
    flightReference: "1234567",
    date: "30 Jun 2023",
    flightNum: "3999",
    from: "Paris",
    to: "Cairo",
    departureTime: "03:00 am",
    arrivalTime: "22:20 pm",
    // creator: "Ali",
    // seen: "Ahmed + 2",
    attachments: `file${e + 1}.pdf`,
    note: "No comments",
  });
});

export default tableData;
