import { useEffect, useState } from "react";
import { Table } from "antd";
import Column from "antd/es/table/Column";
import { useGetAllAccommodationMutation } from "apis/services/arrival";
import { useNavigate, useSearchParams } from "react-router-dom";
import columns from "components/AddNewArrivalTables/Columns/Accommodation";
import DebouncedSearch from "components/inputs/DebouncedSearch";
import { CiSearch } from "react-icons/ci";
import COLORS from "constants/Colors";
import styles from "./styles.module.scss";

export default function Accommodation() {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const [getAllAccommodation, { isLoading: isGetAllAccommodationLoading }] =
    useGetAllAccommodationMutation();
  const [allAccommodation, setAllAccommodation] = useState([]);
  const [allAccommodationCount, setAllAccommodationCount] = useState(0);
  const searchTerm = searchParams.get("search") ?? "";

  useEffect(() => {
    getAllAccommodation({
      search: searchTerm ?? "",
      page: searchParams.get("accommodationPage") || "1",
      page_size: 50,
    })
      .unwrap()
      .then((res) => {
        setAllAccommodation(res.results);
        setAllAccommodationCount(res.count);
      });
  }, [searchParams, allAccommodationCount]);
  const handleClickRow = (record: any) => {
    navigate(`/Dashboard/reservation-details?id=${record.arrival}`);
  };

  const adjustedColumns = columns.map((column) => {
    switch (column.dataIndex) {
      case "accByWho":
        return { ...column, dataIndex: "by" };
      case "accGuestName":
        return { ...column, dataIndex: ["member", "name"] };
      case "accCity":
        return { ...column, dataIndex: ["city", "name"] };
      case "accType":
        return { ...column, dataIndex: "category" };
      case "hotelName":
        return { ...column, dataIndex: ["hotel_cruise", "name"] };
      case "guideInVisits":
        return { ...column, dataIndex: ["guide", "username"] };
      case "currency":
        return { ...column, dataIndex: ["currency", "name"] };
      case "status":
        return {
          ...column,
          render: (text: any) => {
            const statusStyle =
              text === "waiting" ? styles.waiting : styles.booked;
            return <span className={statusStyle}>{text}</span>;
          },
        };
      default:
        return column;
    }
  });

  return (
    <div>
      <DebouncedSearch
        onSearchChange={(v) => {
          searchParams.set("search", v);
          setSearchParams(searchParams);
        }}
        containerStyle={styles.tableInput}
        placeholder="Search"
        inputPrefix={<CiSearch color={COLORS.Neutral700} size={26} />}
      />
      <Table
        dataSource={allAccommodation}
        loading={isGetAllAccommodationLoading}
        style={{ marginTop: "20px" }}
        scroll={{ x: "max-content" }}
        onRow={(record, rowIndex) => ({
          onClick: () => {
            handleClickRow(record);
          },
        })}
        className={styles.cursorPointer}
        sticky={{ offsetHeader: 0 }}
        pagination={{
          onChange: (page) => {
            searchParams.set("accommodationPage" || "", String(page));
            setSearchParams(searchParams);
          },
          total: allAccommodationCount,
          pageSize: 50,
        }}
      >
        {adjustedColumns.map((column) => (
          <Column
            title={column.title}
            dataIndex={column.dataIndex}
            key={column.key}
            render={column.render}
            width={column.width}
          />
        ))}
      </Table>
    </div>
  );
}
