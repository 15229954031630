import { useState } from "react";
import { Dropdown, Space } from "antd";
import { CiSearch } from "react-icons/ci";
import DateFilter from "components/modals/DateFilter";
import FilterDropDownModal from "components/modals/DropdownFilter";
import Button from "components/general/Button";
import DebouncedSearch from "components/inputs/DebouncedSearch";
import BorderedWhiteBtn from "components/BorderedWhiteBtn";
import Text from "components/general/Text";
import Icon from "components/general/Icon";
import COLORS from "constants/Colors";
import { useSearchParams } from "react-router-dom";
import IProps from "./types";
// import { DownOutlined } from "@ant-design/icons";
import styles from "./styles.module.scss";

export default function MainTableLayout({
  title,
  subTitle,
  mainBtnText,
  mainBtnSuffix,
  mainBtnClassName,
  secondaryBtnText,
  secondaryBtnClassName,
  mainBtnOnClick,
  mainBtnDisabled = false,
  secondaryBtnOnClick,
  children,
  hideSearch,
}: IProps) {
  const [isDateFilterVisible, setIsDateFilterVisible] = useState(false);
  const [isclientFilterVisible, setIsclientFilterVisible] = useState(false);
  const [isFileilterVisible, setIsFileFilterVisible] = useState(false);
  const [isAgentFilterVisible, setIsAgentFilterVisible] = useState(false);

  const [searchParams, setSearchParams] = useSearchParams();
  const handleFilter = () => {
    setIsDateFilterVisible(true);
    searchParams.set("filterType", "date");
    setSearchParams(searchParams);
  };

  const items = [
    {
      label: (
        <button className={styles.DropdownBtn} onClick={handleFilter}>
          Date
        </button>
      ),
      key: "0",
    },
  ];
  const Clientitems = [
    {
      label: "Client_Name_1",
      key: "0",
    },
    {
      label: "Client_Name_2",
      key: "1",
    },
    {
      label: "Client_Name_3",
      key: "2",
    },
    {
      label: "Client_Name_4",
      key: "3",
    },
  ];
  const Fileitems = [
    {
      label: "File_Name_1",
      key: "0",
    },
    {
      label: "File_Name_2",
      key: "1",
    },
    {
      label: "File_Name_3",
      key: "2",
    },
    {
      label: "File_Name_4",
      key: "3",
    },
  ];
  const Agentitems = [
    {
      label: "Agent_File_Name_1",
      key: "0",
    },
    {
      label: "Agent_File_Name_2",
      key: "1",
    },
    {
      label: "Agent_File_Name_3",
      key: "2",
    },
    {
      label: "Agent_File_Name_4",
      key: "3",
    },
    {
      label: "Agent_File_Name_1",
      key: "0",
    },
    {
      label: "Agent_File_Name_2",
      key: "1",
    },
    {
      label: "Agent_File_Name_3",
      key: "2",
    },
    {
      label: "Agent_File_Name_4",
      key: "3",
    },
    {
      label: "Agent_File_Name_1",
      key: "0",
    },
    {
      label: "Agent_File_Name_2",
      key: "1",
    },
    {
      label: "Agent_File_Name_3",
      key: "2",
    },
    {
      label: "Agent_File_Name_4",
      key: "3",
    },
  ];
  return (
    <div className={`${styles.tableWrapper}`}>
      <div className={`${styles.tableHeader}`}>
        <div className={`${styles.titleContainer}`}>
          <h2>{title}</h2>
          {subTitle && <Text>{subTitle}</Text>}
        </div>
        {secondaryBtnText &&
          (hideSearch || (
            <Button
              isOutline
              onClick={secondaryBtnOnClick}
              btnClassName={secondaryBtnClassName}
            >
              {secondaryBtnText}
            </Button>
          ))}
        {mainBtnText && (
          <Button
            suffix={mainBtnSuffix}
            onClick={mainBtnOnClick}
            btnClassName={mainBtnClassName}
            disabled={mainBtnDisabled}
          >
            {mainBtnText}
          </Button>
        )}
      </div>
      {hideSearch || (
        <div className={`${styles.tableActions}`} style={{ marginBottom: 0 }}>
          <DebouncedSearch
            onSearchChange={(v) => {
              searchParams.set("search", v);
              setSearchParams(searchParams);
            }}
            containerStyle={styles.tableInput}
            placeholder="Search"
            inputPrefix={<CiSearch color={COLORS.Neutral700} size={26} />}
          />

          <BorderedWhiteBtn
            containerStyle={`${styles.filterContainer}`}
            // onClick={handleFilter}
          >
            <Dropdown
              menu={{
                items,
              }}
              trigger={["click"]}
            >
              <div>
                <Space>
                  <Icon name="filter" size={22} color="TextPrimaryBlack48" />
                  <span>Filter</span>
                </Space>
              </div>
            </Dropdown>
          </BorderedWhiteBtn>
        </div>
      )}
      <div>{children}</div>
      <DateFilter
        setIsVisible={() => setIsDateFilterVisible(false)}
        isVisible={isDateFilterVisible}
      />

      <FilterDropDownModal
        data={Clientitems}
        filterType="Client Name"
        setIsVisible={() => setIsclientFilterVisible(false)}
        isVisible={isclientFilterVisible}
      />
      <FilterDropDownModal
        data={Fileitems}
        filterType="File"
        setIsVisible={() => setIsFileFilterVisible(false)}
        isVisible={isFileilterVisible}
      />
      <FilterDropDownModal
        data={Agentitems}
        filterType="Agent File"
        setIsVisible={() => setIsAgentFilterVisible(false)}
        isVisible={isAgentFilterVisible}
      />
    </div>
  );
}
