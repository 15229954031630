import { toast } from "react-toastify";

export default function HandleErrors(err: any) {
  const showErrorToast = (msg: string) => {
    toast.error(msg);
  };

  if (!err) {
    console.log("err=>:", err);
    return;
  }
  // if (err?.code?.includes("token_not_valid")) {
  //   store.dispatch(logout());
  // }
  if (err?.message) {
    showErrorToast(err?.message);
  } else if (err?.details?.length) {
    showErrorToast(err?.details.join(", "));
  } else if (err?.detail) {
    showErrorToast(err?.detail);
  } else if (Object.keys(err)?.length !== 0) {
    showErrorToast(String(Object.values(err)[0]));
  } else {
    showErrorToast("خطأ غير متوقع!");
  }
}
