export default function prepareAccommodationData(data: any) {
  const getName = (obj: { name: string }) => {
    return obj?.name;
  };

  const newData = data.map((ele: any, index: number) => {
    return {
      ...ele,
      guideInVisits: ele?.guide?.username,
      accByWho: ele.by,
      accType: ele.type,
      accGuestName: getName(ele.member),
      accCity: getName(ele.city),
      hotelName: getName(ele.hotel_cruise),
      category: ele.category,
      currency: ele.currency.name,
      key: `${index}`,
      num: index <= 9 ? `0${index}` : `${index}`,
    };
  });
  return newData;
}
