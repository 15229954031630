import Dimensions from "constants/Dimensions";
import { ColumnTypes } from "../types";
import styles from "../styles.module.scss";

const isMobileView = Dimensions.windowWidth <= 480;

const columns: (ColumnTypes[number] & {
  editable?: boolean;
  dataIndex: string;
})[] = [
  {
    title: "",
    dataIndex: "empty",
    key: "empty",
    width: 20,
    fixed: "left",
  },
  // {
  //   title: "Num.",
  //   dataIndex: "num",
  //   width: 188,
  //   // editable: true,
  // },
  {
    title: "By",
    dataIndex: "accByWho",
    width: 188,
    editable: true,
  },
  {
    title: "Reservation Num.",
    dataIndex: "reservation_number",
    width: 188,
    editable: true,
  },
  {
    title: "Guest Name",
    dataIndex: "accGuestName",
    width: 188,
    editable: true,
  },
  {
    title: "City",
    dataIndex: "accCity",
    width: 188,
    editable: true,
  },
  {
    title: "Type",
    dataIndex: "accType",
    width: 188,
    editable: true,
  },
  {
    title: "Check In",
    dataIndex: "check_in",
    width: 188,
    editable: true,
  },
  {
    title: "Check Out",
    dataIndex: "check_out",
    width: 188,
    // editable: true,
  },
  {
    title: "Hotel / Cruise",
    dataIndex: "hotelName",
    width: 188,
    editable: true,
  },
  {
    title: "Category",
    dataIndex: "category",
    width: 188,
    editable: true,
  },
  {
    title: "Days",
    dataIndex: "tours_days",
    width: 188,
    editable: true,
  },
  {
    title: "Nights",
    dataIndex: "night",
    width: 188,
    editable: true,
  },
  {
    title: "Rooms",
    dataIndex: "rooms",
    width: 188,
    editable: true,
  },
  {
    title: "Room Type",
    dataIndex: "room_type",
    width: 188,
    editable: true,
  },
  {
    title: "Basis",
    dataIndex: "basis",
    width: 188,
    editable: true,
  },
  {
    title: "Guide",
    dataIndex: "guideInVisits",
    width: 188,
    editable: true,
  },
  {
    title: "Payment",
    dataIndex: "payment",
    width: 188,
    editable: true,
  },
  {
    title: "Due Date",
    dataIndex: "due_date",
    width: 188,
    editable: true,
  },
  {
    title: "Cost PP/ Night",
    dataIndex: "cost_PP_night",
    width: 188,
    editable: true,
  },
  {
    title: "Per",
    dataIndex: "per",
    width: 188,
    editable: true,
  },
  {
    title: "Currency",
    dataIndex: "currency",
    width: 188,
    editable: true,
  },
  {
    title: "Total Cost",
    dataIndex: "total_cost",
    width: 188,
    editable: false,
  },
  {
    title: "Status",
    dataIndex: "status",
    // fixed: !isMobileView ? "right" : undefined,
    width: 188,
    editable: true,
    render: (value) => {
      return (
        <span
          className={`${styles.statusFlag} ${
            value === "waiting" ? styles.waiting : styles.booked
          }`}
        >
          {value}
        </span>
      );
    },
  },
];

export default columns;
