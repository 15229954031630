import dayjs from "dayjs";
import { useSearchParams } from "react-router-dom";
import { useEffect, useState } from "react";
import moment from "moment";
import PathwayRow from "components/PathwayRow";
import Icon from "components/general/Icon";
import Text from "components/general/Text";
import MainTableLayout from "components/wrappers/MainTableLayout";
import DIMENSIONS from "constants/Dimensions";
import DUMMY_DATA from "DATA";
import { useGetTrafficDataQuery } from "apis/services/arrival";
import styles from "./styles.module.scss";
import preparePathway from "./Utils/preparePathway";

const isMobile = DIMENSIONS.windowWidth < 480;
const columnWidth = !isMobile ? "125px" : "90px";
const rowMinHeight = "150px";

function getEmptyObj(dayIndex: number, length: number, defaultValues: object) {
  return {
    ...defaultValues,
    key: `${dayIndex}-${length} path_data`,
    id: `${length * 1000}`,
    pathway: "",
    time: "",
    city: "",
    adultNum: 4,
    childNum: 2,
    pickUp: "",
    drop: "",
    ticketNum: "",
    ticketPrice: "",
    guideInVisits: "",
    leaderName: "",
    leaderFees: "",
    driverName: "",
    vehicle: "",
    vehicleFees: "",
    note: "",
  };
}

export default function Pathways({ arrivalData }: { arrivalData: any }) {
  const [searchParams] = useSearchParams();

  const [subTitleText, setSubTitleText] = useState("");
  const [weekDays, setWeekDays] = useState<any[]>([]);
  const [rowsToBeMerged, setRowsToBeMerged] = useState<any[]>([]);
  const [isMergeLoading, setIsMergeLoading] = useState(false);

  const handleMergeRow = () => {
    setIsMergeLoading(true);
    const arrForMerge: any[] = [];

    rowsToBeMerged.forEach((row) => {
      const rowDate = row.date;
      const rowIds = [...row.ids];
      if (rowIds.length > 1) {
        const copyArr = [...weekDays];
        const indexOfWeekDay = copyArr.findIndex(
          (weekDay) => weekDay.date === rowDate
        );
        const pathwayDataToBeMerged = copyArr[
          indexOfWeekDay
        ].pathwayData.filter((pth: any) => {
          return rowIds.includes(pth?.id);
        });

        const pathwayDataNotToBeMerged = copyArr[
          indexOfWeekDay
        ].pathwayData.filter((pth: any) => {
          return !rowIds.includes(pth?.id);
        });

        copyArr[indexOfWeekDay] = {
          ...copyArr[indexOfWeekDay],
          pathwayData: [...pathwayDataNotToBeMerged],
          mergedPathwayData: [
            ...copyArr[indexOfWeekDay].mergedPathwayData,
            [...pathwayDataToBeMerged],
          ],
        };

        arrForMerge.push(copyArr[indexOfWeekDay]);
      }
    });

    if (arrForMerge.length) {
      setWeekDays((prev) => {
        const copyPrevArr = [...prev];
        arrForMerge.forEach((ele) => {
          const indexOfPrevEle = copyPrevArr.findIndex(
            (prevArrEle) => prevArrEle.id === ele.id
          );
          copyPrevArr.splice(indexOfPrevEle, 1, ele);
        });

        return [...copyPrevArr];
      });
    } else {
      setIsMergeLoading(false);
    }
  };

  useEffect(() => {
    const startDate = searchParams.get("Filearrival_date");
    const endDate = searchParams.get("Fileleave_date");

    const formattedStartDate = startDate
      ? dayjs(startDate).format("DD MMM")
      : "Invalid Date";
    const formattedEndDate = endDate
      ? dayjs(endDate).format("DD MMM")
      : "Invalid Date";

    setSubTitleText(`From ${formattedStartDate} To ${formattedEndDate}`);

    setWeekDays((prev) => {
      const newData = prev.map((e, i) => {
        return {
          ...e,
        };
      });

      return [...newData];
    });
  }, [searchParams]);

  const [editingKey, setEditingKey] = useState("");
  const [isAddNewRowLoading, setIsAddNewRowLoading] = useState(false);

  const { data: trafficData, isLoading: isTrafficDataLoading } =
    useGetTrafficDataQuery({
      page_size: 10000,
      arrival: searchParams.get("id"),
    });

  const handleAddNewRow = () => {
    if (!weekDays.length) {
      setEditingKey(`${0}-${0} path_data`);
      setWeekDays([
        {
          id: `${0}-ee`,
          date: moment().locale("en").format("DD-MM-YYYY"),
          mergedPathwayData: [],
          pathwayData: [
            getEmptyObj(0, 0, {
              fileNum: trafficData?.results[0]?.arrival?.file_number,
              agentFileNum: trafficData?.results[0]?.arrival?.agent_file_number,
            }),
          ],
        },
      ]);
    } else {
      setEditingKey(`${0}-${weekDays[0]?.pathwayData.length} path_data`);

      setWeekDays((prev) => {
        const pArr = [...prev];

        pArr.splice(0, 1, {
          ...pArr[0],
          pathwayData: [
            ...pArr[0].pathwayData,
            getEmptyObj(0, pArr[0].pathwayData.length, {
              fileNum: trafficData?.results[0]?.arrival?.file_number,
              agentFileNum: trafficData?.results[0]?.arrival?.agent_file_number,
            }),
          ],
        });

        return [...pArr];
      });
    }
  };

  useEffect(() => {
    if (trafficData?.results) {
      const sortedData = [...trafficData.results].sort((a, b) => {
        const dateA = a.date || a.flight?.date || null;
        const dateB = b.date || b.flight?.date || null;

        if (!dateA && !dateB) return 0;
        if (!dateA) return 1;
        if (!dateB) return -1;

        return new Date(dateB).getTime() - new Date(dateA).getTime();
      });

      setWeekDays(preparePathway(sortedData));
    }
  }, [trafficData?.results]);

  return (
    <MainTableLayout
      title="Traffic"
      mainBtnText="Add New"
      mainBtnSuffix={<Icon name="arrow-down" size={24} />}
      mainBtnClassName={styles.btnStyle}
      secondaryBtnClassName={styles.btnStyle}
      secondaryBtnText="Merge Group"
      mainBtnOnClick={handleAddNewRow}
      mainBtnDisabled={!!editingKey}
      secondaryBtnOnClick={handleMergeRow}
      hideSearch
    >
      <div>
        {/* Table Header */}
        <div className={`${styles.tableHeader}`}>
          <Text
            className={`${styles.dateColumn}`}
            style={{ width: columnWidth }}
          >
            Date
          </Text>

          <Text className={`${styles.pathwayColumn}`}>Traffic Type</Text>
        </div>
        {/* Table Rows */}
        {!isMergeLoading && !isTrafficDataLoading && !isAddNewRowLoading ? (
          <div>
            {weekDays.length > 0 &&
              weekDays?.map((e) => (
                <PathwayRow
                  key={`${e?.id}-key`}
                  date={e?.date}
                  data={e?.pathwayData}
                  mergedData={[]}
                  setRowsToBeMerged={() => {}}
                  columnWidth={columnWidth}
                  rowMinHeight={rowMinHeight}
                  editingKey={editingKey}
                  setEditingKey={setEditingKey}
                  showCheckBox={false}
                  setIsAddNewRowLoading={setIsAddNewRowLoading}
                />
              ))}
          </div>
        ) : (
          <div style={{ paddingBlock: 20, textAlign: "center" }}>
            <Text style={{ textAlign: "center" }}>Loading...</Text>
          </div>
        )}
      </div>
    </MainTableLayout>
  );
}
