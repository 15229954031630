import { useState } from "react";
import moment from "moment";
import PageHeader from "components/PageHeader";
import styles from "./styles.module.scss";

export default function MainLayoutHeader() {
  const [getArrivalDate, setArrivalDate] = useState("");
  const [getLeaveDate, setLeaveDate] = useState("");

  setTimeout(() => {
    const url = new URL(window.location.href);
    const searchParams = new URLSearchParams(url.search);
    const fileArrivalDate = searchParams.get("Filearrival_date");
    setArrivalDate(fileArrivalDate ?? "");
    const fileLeaveDate = searchParams.get("Fileleave_date");
    setLeaveDate(fileLeaveDate ?? "");
  }, 3000);

  const pathName =
    window.location.pathname === "/Dashboard/reservation-details"
      ? "File"
      : window.location.pathname === "/Dashboard/pathways"
        ? "Traffic"
        : window.location.pathname === "/Dashboard/accommodation"
          ? "Accommodation"
          : window.location.pathname === "/Dashboard"
            ? "Arrival List"
            : "";
  return (
    <div className={styles.mainHeader}>
      <PageHeader
        mainTitle={pathName}
        secondTitle={
          window.location.pathname === "/Dashboard/reservation-details"
            ? `${moment(getArrivalDate).locale("en").format("DD MMMM")} - ${
                moment(getLeaveDate).isValid()
                  ? moment(getLeaveDate).locale("en").format("DD MMMM YYYY")
                  : ""
              }`
            : ""
        }
      />
    </div>
  );
}
