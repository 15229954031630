import { useState, useRef, useEffect } from "react";
import Image from "components/general/Image";
import {
  useGetArrivalDataByIdMutation,
  usePostAttachmentDataMutation,
  useDeleteAttachmentMutation,
} from "apis/services/arrival";
import { CgClose } from "react-icons/cg";

import Button from "components/general/Button";

import TableMockup from "components/AddNewArrivalTables/TableMockup";
import addIcon from "assets/images/add.svg";
import pdfIcon from "assets/images/pdfIcon.png";
import pngIcon from "assets/images/pngIcon.png";
import styles from "./styles.module.scss";

type AttachmentTableProps = {
  id: any;
};

export default function AttachmentComponent({ id }: AttachmentTableProps) {
  const [selectedFiles, setSelectedFiles] = useState<File[]>([]);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [arrival, setArrival] = useState<any>(null);
  const [getArrivalData, { isLoading: isArrivalDataLoading }] =
    useGetArrivalDataByIdMutation();
  const [AddAttachment, { isLoading: isPostDataLoading }] =
    usePostAttachmentDataMutation();
  const [uploadedAttachment, setuploadedAttachment] = useState<any | File>("");
  const [deleteAttachment] = useDeleteAttachmentMutation();

  // Fetch arrival data on component mount
  const fetchData = async () => {
    try {
      const response = await getArrivalData(id).unwrap();
      setArrival(response.data);
    } catch (error) {
      console.error("Error fetching arrival data:", error);
    }
  };
  useEffect(() => {
    fetchData();
  }, []);
  const Arrival = id;

  const handleFileInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { files } = event.target;
    setuploadedAttachment(event.target);

    if (files) {
      setSelectedFiles((prevFiles) => [...prevFiles, ...Array.from(files)]);
    }
  };

  const handleButtonClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };
  const handleDeleteAttachment = (id: number) => {
    deleteAttachment(id)
      .unwrap()
      .then(() => fetchData())
      .catch((err: any) => console.log(err));
  };
  const handleSaveAttachments = (Attachments: any) => {
    const formData = new FormData();
    Attachments.forEach((file: any) => {
      formData.append("attachment", file);
      formData.append("arrival", Arrival);
      formData.append("file_name", file.name);
      AddAttachment([formData])
        .unwrap()
        .then((res) => {
          fetchData();
          setSelectedFiles([]);
        })
        .finally(() => {})
        .catch((err) => console.log(err));
    });
  };

  return (
    <div className={styles.tableWrapper}>
      <TableMockup title="Attachments" handleCheck="">
        {isArrivalDataLoading || isPostDataLoading ? (
          "Loading..."
        ) : (
          <>
            <div className={styles.content}>
              {selectedFiles.map((file) => (
                <div key={file.name} className={styles.addNewFile}>
                  {file.type === "application/pdf" ? (
                    <Image src={pdfIcon} alt="pdf icon" />
                  ) : (
                    <Image src={pngIcon} alt="png icon" />
                  )}
                  <p className={styles.btnTitle}>{file.name}</p>
                </div>
              ))}

              {arrival &&
                arrival?.arrival_attachments?.map((attachment: any) => {
                  // Extract file extension from file name
                  const fileExtension = attachment.file_name
                    ?.split(".")
                    ?.pop()
                    ?.toLowerCase();
                  // Determine file type based on file extension
                  const isPdf = fileExtension === "pdf";

                  return (
                    <div key={attachment.id} className={styles.addNewFile}>
                      <Button
                        btnClassName={`${styles.deleteBtn}`}
                        onClick={() => handleDeleteAttachment(attachment.id)}
                      >
                        <CgClose />
                      </Button>
                      <a
                        href={attachment?.attachment}
                        rel="noreferrer"
                        target="_blank"
                      >
                        {isPdf ? (
                          <Image src={pdfIcon} alt="pdf icon" />
                        ) : (
                          <Image src={pngIcon} alt="png icon" />
                        )}
                        <p className={styles.btnTitle}>
                          {attachment.file_name}
                        </p>
                      </a>
                    </div>
                  );
                })}

              <button className={styles.addNewFile} onClick={handleButtonClick}>
                <Image src={addIcon} alt="add icon" />
                <p className={styles.btnTitle}>Add new file</p>
              </button>

              <input
                type="file"
                ref={fileInputRef}
                onChange={handleFileInput}
                style={{ display: "none" }}
              />
            </div>
            <div style={{ margin: "1rem 3rem" }}>
              <Button
                btnClassName={`${styles.saveButton}`}
                onClick={() => {
                  handleSaveAttachments(selectedFiles);
                }}
              >
                Save Attachments
              </Button>
            </div>
          </>
        )}
      </TableMockup>
    </div>
  );
}
