import Icon from "../Icon";
import { CustomToastProps } from "./types";
import styles from "./styles.module.scss";

export default function CustomToast({
  toastType,
  title,
  msg,
}: CustomToastProps) {
  const icons = {
    warning: "Warning",
    danger: "Error",
    info: "Info",
    success: "Done",
  };
  return (
    <div className={styles.container}>
      <div>
        <Icon name={icons[toastType]} size={24} />
      </div>
      <div>
        {title && <p className="Paragraph100Heavy m-0">{title}</p>}
        {msg && <p className="Paragraph200Light m-0">{msg}</p>}
      </div>
    </div>
  );
}
