import { DatePicker, Form, Select } from "antd";
import React, { useState } from "react";
import type { DatePickerProps } from "antd";
import { useNavigate } from "react-router-dom";
import Button from "components/general/Button";
import TextInput from "components/inputs/TextInput";
import { usePostArrivalDataMutation } from "apis/services/arrival";
import Text from "components/general/Text";
import styles from "./styles.module.scss";
import TableMockup from "./TableMockup";

//

function CountryCode() {
  return (
    <div className={`${styles.countryCodeContainer}`}>
      <Select
        className={`${styles.selectInput}`}
        defaultActiveFirstOption
        defaultValue="egp"
        options={[
          {
            value: "egp",
            label: "EGP",
          },
          {
            value: "usa",
            label: "USA",
          },
          {
            value: "ksa",
            label: "KSA",
          },
        ]}
      />
      <TextInput containerStyle={`${styles.inputField}`} />
    </div>
  );
}

export default function ArrivalInfo() {
  const [AddArrival, { isLoading }] = usePostArrivalDataMutation();
  const [arrivalDate, setarrivalDate] = useState<any>(null);
  const [onDateChange, setOnDateChange] = useState<any>(null);
  const [arrivalFileNum, setarrivalFileNum] = useState<string | null>(null);
  const [arrivalgestName, setarrivalgestName] = useState<string | null>(null);

  const navigate = useNavigate();

  const handleFinish = (data: FormData) => {
    const values = {
      arrival_date: arrivalDate,
      agent_file_number: arrivalFileNum,
      guest_name: arrivalgestName,
    };
    AddArrival(values)
      .unwrap()
      .then((res) =>
        navigate(`/Dashboard/reservation-details?id=${res?.id}`, {
          state: { isReservation: true, arrivalData: values },
        })
      )
      .finally(() => {})
      .catch((err) => console.log(err));
  };

  const handleOnDateChange: DatePickerProps["onPanelChange"] = (date: any) => {
    setOnDateChange(date);
  };

  const onChange: DatePickerProps["onChange"] = (
    date: any,
    dateString: any
  ) => {
    setarrivalDate(dateString);
    setOnDateChange(date);
  };

  return (
    <TableMockup title="Add New Arrival" handleCheck="">
      <div className={`${styles.formContainer} newArrival`}>
        <Form
          className={styles.form}
          autoComplete="off"
          autoCorrect="off"
          onFinish={handleFinish}
        >
          <Form.Item name="arrival_date">
            <Text className={`${styles.labelText}`}>Arrival Date</Text>
            <DatePicker
              onChange={onChange}
              className={`${styles.datePickerStyle}`}
              onPanelChange={handleOnDateChange}
              value={onDateChange}
            />
          </Form.Item>
          <Form.Item name="agent_file_number" rules={[{ required: true }]}>
            <TextInput
              onChange={(e) => {
                setarrivalFileNum(e.target.value);
              }}
              i18nLabelKey="agent_file_number"
            />
          </Form.Item>
          <Form.Item name="guest_name" rules={[{ required: true }]}>
            <TextInput
              onChange={(e) => {
                setarrivalgestName(e.target.value);
              }}
              i18nLabelKey="guest_name"
            />
          </Form.Item>
          <Button btnClassName={`${styles.saveButton}`} type="submit">
            Save New Arrival
          </Button>
        </Form>
      </div>
    </TableMockup>
  );
}
