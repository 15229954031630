import { useRef } from "react";
import Button from "components/general/Button";
import Image from "components/general/Image";
import FileIcon from "assets/images/upload.svg";

export default function UploadInput() {
  const fileInputRef = useRef<HTMLInputElement>(null);

  const handleFileInput = (item: React.ChangeEvent<HTMLInputElement>) => {
    // console.log("item:: ", item);
  };

  const handleButtonClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  return (
    <div>
      <Button
        color="BorderGray"
        suffix={<Image src={FileIcon} width={24} style={{ opacity: "0.32" }} />}
        onClick={handleButtonClick}
        isOutline
      >
        upload the file
      </Button>
      <input
        type="file"
        ref={fileInputRef}
        onChange={handleFileInput}
        style={{ display: "none" }}
      />
    </div>
  );
}
