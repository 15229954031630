import {
  AccommodationTable,
  FlightTable,
  MembersTable,
  PathwayTable,
} from "components/AddNewArrivalTables";
import HandleCheckbox from "components/AddNewArrivalTables/HandleCheckbox";
import { useSearchParams } from "react-router-dom";
import AttachmentComponent from "components/AttachmentComponent";
import { useGetArrivalDataByIdMutation } from "apis/services/arrival";
import ReservationDetailsContextProvider from "contexts/reservationDetailsContext";
import styles from "./styles.module.scss";

export default function ReservationDetails() {
  const [searchParams] = useSearchParams();
  const id = searchParams.get("id");

  const [arrivalDatawithId, { data: arrivalData }] =
    useGetArrivalDataByIdMutation();
  // useEffect(() => {
  //   if (id) {
  //     arrivalDatawithId(id)
  //       .unwrap()
  //       .then(() => {
  //         console.log("DONE:: ");
  //       });
  //   }
  // }, [id]);

  return (
    <ReservationDetailsContextProvider arrivalId={id}>
      <section>
        <div className={styles.container}>
          <HandleCheckbox />
          <MembersTable id={id} />
          <FlightTable id={id} />
          <AccommodationTable id={id} />
          <PathwayTable arrivalData={arrivalData} />
          <AttachmentComponent id={id} />
        </div>
      </section>
    </ReservationDetailsContextProvider>
  );
}
