import { useState } from "react";
import Icon from "components/general/Icon";
import { NavLink, useLocation } from "react-router-dom";
import Image from "components/general/Image";
import MenuImg from "assets/images/menu.svg";
import cancelImg from "assets/images/cancel.png";
import LOGOWITHTEXTIMG from "assets/images/logoWithText.png";
import { NavigationListItem } from "./types";
import MainLayoutInfo from "../MainLayoutInfo";
import MainLayoutHeader from "../MainLayoutHeader";
import styles from "./styles.module.scss";

//
interface IProps {
  iconName: string;
  title: string;
  isActive: boolean;
}

function SidebarButton({ iconName, title, isActive }: IProps) {
  return (
    <div className={styles.sidebarButtonContainer}>
      <Icon
        name={iconName}
        size={24}
        color={isActive ? "Primary700" : "IconGray"}
      />
      <span className={`${styles.open} ${isActive && styles.active}`}>
        {title}
      </span>
    </div>
  );
}

export default function SidebarLayout({
  children,
}: {
  children: React.ReactNode;
}) {
  const { state } = useLocation();

  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  const handleSidebarButtonClick = () => {
    setTimeout(() => {
      toggleSidebar();
    }, 100);
  };

  const navigationList: NavigationListItem[] = [
    {
      title: "Dashboard",
      iconName: "home",
      href: "/Dashboard",
    },

    {
      title: "Traffic",
      iconName: "pathway",
      href: "/Dashboard/pathways",
      state: {
        isPathway: true,
      },
    },
    // {
    //   title: "Services",
    //   iconName: "services",
    //   href: "",
    // },
    {
      title: "Reports",
      iconName: "reports",
      href: "/Dashboard/reports",
      state: {
        hideInfo: true,
      },
    },
    {
      title: "Accommodation",
      iconName: "Business",
      href: "/Dashboard/accommodation",
    },
    // {
    //   title: "Users Activity",
    //   iconName: "user-activity",
    //   href: "",
    // },
    // {
    //   title: "Settings",
    //   iconName: "settings",
    //   href: "",
    // },
  ];

  return (
    <div className="h-100">
      <div className={styles.toggleButton}>
        <button className={styles.sideBarButton} onClick={toggleSidebar}>
          <Image src={MenuImg} alt="Menu Image" />
        </button>
      </div>
      <aside
        className={`${styles.sidenav} ${styles.open} ${
          isSidebarOpen && styles.visible
        }`}
      >
        {isSidebarOpen && (
          <button
            className={styles.CancelSideBarButton}
            onClick={toggleSidebar}
          >
            <Image src={cancelImg} alt="cancel Image" />
          </button>
        )}
        <Image
          src={LOGOWITHTEXTIMG}
          alt="logo-rotana"
          height={56}
          width={186}
          style={{
            marginBottom: "43px",
          }}
        />

        <div className={`w-100 ${styles.navigationContent}`}>
          {navigationList?.map((item) => (
            <NavLink
              key={item?.title}
              to={item?.href}
              state={item?.state}
              className={({ isActive }) =>
                isActive && !!item.href ? styles.active : ""
              }
              end
              onClick={handleSidebarButtonClick}
            >
              {({ isActive }) => (
                <SidebarButton
                  iconName={item?.iconName}
                  title={item?.title}
                  isActive={isActive && !!item.href}
                />
              )}
            </NavLink>
          ))}
          <div>
            <NavLink
              to="/"
              onClick={() => {
                localStorage.clear();
                // window.location.reload();
              }}
            >
              <SidebarButton
                iconName="ArrowLeft"
                title="LogOut"
                isActive={false}
              />
            </NavLink>
          </div>
        </div>
      </aside>

      <main className={styles.sidelayoutContent}>
        <MainLayoutHeader />
        {!state?.hideInfo ? <MainLayoutInfo /> : ""}
        {children}
      </main>
    </div>
  );
}
