import Button from "components/general/Button";
import { useNavigate } from "react-router-dom";
import Text from "components/general/Text";
import styles from "./styles.module.scss";

interface AddNewArrivalHeaderProps {
  onSave: () => void;
  onCancel: () => void;
}

function AddNewArrivalHeader({ onSave, onCancel }: AddNewArrivalHeaderProps) {
  const navigate = useNavigate();

  return (
    <div className={`${styles.addNewArrivalContainer}`}>
      <Text className={`${styles.headerTitle}`}>Add New Arrival</Text>
      <div className={`${styles.buttonContainer}`}>
        <Button
          isOutline
          btnClassName={`${styles.cancelButton}`}
          onClick={() => {
            onCancel();
            navigate(`/Dashboard`);
          }}
        >
          Cancel
        </Button>
        {/* <Button btnClassName={`${styles.saveButton}`} onClick={onSave}>
          Save New Arrival
        </Button> */}
      </div>
    </div>
  );
}
export default AddNewArrivalHeader;
