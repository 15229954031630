import type { CheckboxChangeEvent } from "antd/es/checkbox";
import { Checkbox } from "antd";
import { CheckBoxProps } from "./types";
import styles from "./styles.module.scss";

export default function CheckBox({
  containerStyle,
  text,
  boxStyle,
  onValueChange,
  defaultChecked = false,
}: CheckBoxProps) {
  const onChange = (e: CheckboxChangeEvent) => {
    // console.log(`checked = ${e.target.checked}`);
    onValueChange?.(e.target.checked);
  };

  return (
    <div className={`${styles.container} ${containerStyle}`}>
      <Checkbox
        className={`${boxStyle} ${styles.insideBox}`}
        defaultChecked={defaultChecked}
        onChange={onChange}
      >
        {text}
      </Checkbox>
    </div>
  );
}
