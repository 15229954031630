import AUTHIMG from "assets/images/login.jpg";
import LOGOWITHTEXTIMG from "assets/images/logoWithText.png";
import Image from "components/general/Image";
import { AuthLayoutProps } from "./types";
import styles from "./styles.module.scss";

export default function AuthLayout({ children }: AuthLayoutProps) {
  return (
    <section>
      <div className={styles.container}>
        <div className={styles.formSection}>
          <Image
            src={LOGOWITHTEXTIMG}
            alt="logo-rotana"
            height={90}
            width={290}
            className={styles.image}
          />
          <div className={styles.formContainer}>{children}</div>
        </div>

        <div className={styles.logoSection}>
          <div
            className={styles.imgElement}
            style={{
              backgroundImage: `url(${AUTHIMG})`,
            }}
          />
        </div>
      </div>
    </section>
  );
}
