import TableMockup from "./TableMockup";

function HandleCheckbox() {
  return (
    <TableMockup title="Handled" handleCheck="button">
      {" "}
    </TableMockup>
  );
}
export default HandleCheckbox;
