import { Table } from "antd";
import ModalComponent from "components/ModalComponent";
import Text from "components/general/Text";
import IProps from "./types";
import styles from "./styles.module.scss";

//
const columns = [
  {
    title: "Num.",
    dataIndex: "guestsNum",
    key: "guestsNum",
  },
  {
    title: "Name",
    dataIndex: "name",
    key: "name",
  },
  {
    title: "Nationality",
    dataIndex: "nationality",
    key: "nationality",
  },
  {
    title: "Maturity",
    dataIndex: "maturity",
    key: "maturity",
  },
  {
    title: "Age",
    dataIndex: "age",
    key: "age",
  },
  {
    title: "Gender",
    dataIndex: "gender",
    key: "gender",
  },
  // {
  //   title: "Phone Number",
  //   dataIndex: "phoneNumber",
  //   key: "phoneNumber",
  // },
  {
    title: "Arrival Date",
    dataIndex: "arrivalDate",
    key: "arrivalDate",
  },
  {
    title: "Leave Date",
    dataIndex: "leaveDate",
    key: "leaveDate",
  },
  {
    title: "Cities",
    dataIndex: "cities",
    key: "cities",
  },
  {
    title: "Package Type",
    dataIndex: "packageType",
    key: "packageType",
  },
];
const data: object[] = [];
Array.from(Array(5).keys()).forEach((e) => {
  data.push({
    key: `${e}`,
    guestsNum: `0${e + 1}`,
    name: "Hassan",
    nationality: "Egyptian",
    maturity: "child",
    age: 13,
    gender: "male",
    // phoneNumber: "01200020000",
    arrivalDate: "22 Jun 2020",
    leaveDate: "30 Jul 2022",
    cities: "cairo",
    packageType: "Economy",
  });
});

export default function GuestsTableModal({ isVisible, setIsVisible }: IProps) {
  return (
    <ModalComponent
      centered={false}
      isVisible={isVisible}
      setIsVisible={setIsVisible}
      modalClassName={`${styles.modalContainer}`}
    >
      <div>
        <div className={`${styles.headerContainer}`}>
          <Text>Guests Info</Text>
        </div>
        <Table columns={columns} dataSource={data} scroll={{ x: 1300 }} />
      </div>
    </ModalComponent>
  );
}
