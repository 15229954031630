import { useEffect, useState } from "react";
import { DatePicker, Select, Table } from "antd";
import { Dayjs } from "dayjs";
import {
  useGetAccommodationReportMutation,
  useGetHotelsMutation,
  useGetPathwayNameReportMutation,
  useGetVehicleReportMutation,
} from "apis/services/arrival";
import Column from "antd/es/table/Column";
import PageHeader from "components/PageHeader";
import styles from "./styles.module.scss";

type RangeValue = [Dayjs | null, Dayjs | null] | null;
const { RangePicker } = DatePicker;
export default function Reports() {
  const [getHotels] = useGetHotelsMutation();
  const [AccommodationReport] = useGetAccommodationReportMutation();
  const [pathwayReport] = useGetPathwayNameReportMutation();
  const [vehicleReport] = useGetVehicleReportMutation();
  const [allHotels, setAllHotels] = useState<any>([]);
  const [selectedHotel, setSelectedHotel] = useState<any>([]);
  const [hotelReport, sethotelReport] = useState<any>([]);
  const [trafficReport, setTrafficReport] = useState<any>([]);
  const [vehicleReportData, setVehicleReportData] = useState<any>([]);
  const [value, setValue] = useState<RangeValue>(null);
  const [vehicleValue, setVehicleValue] = useState<RangeValue>(null);
  const [transferDate, setTransferDate] = useState<RangeValue>(null);
  const onChange = (value: string) => {
    setSelectedHotel(value);
  };

  const onSearch = (value: string) => {};

  // Filter option.label match the user type input
  const filterOption = (
    input: string,
    option?: { label: string; value: string }
  ) => (option?.label ?? "").toLowerCase().includes(input.toLowerCase());

  useEffect(() => {
    getHotels({})
      .unwrap()
      .then((res) => {
        setAllHotels(res.results);
        setSelectedHotel(allHotels[0]?.id);
      });
  }, []);

  useEffect(() => {
    let startDate: string | undefined;
    let endDate: string | undefined;

    if (transferDate && transferDate.length === 2) {
      startDate = transferDate[0]?.format("YYYY-MM-DD");
      endDate = transferDate[1]?.format("YYYY-MM-DD");
    }

    vehicleReport({
      start_date: startDate,
      end_date: endDate,
    })
      .unwrap()
      .then((res) => {
        setVehicleReportData(res);
      })
      .catch((err) => console.log(err));
  }, [transferDate, vehicleReportData]);

  useEffect(() => {
    let startDate: string | undefined;
    let endDate: string | undefined;

    if (vehicleValue && vehicleValue.length === 2) {
      startDate = vehicleValue[0]?.format("YYYY-MM-DD");
      endDate = vehicleValue[1]?.format("YYYY-MM-DD");
    }

    pathwayReport({
      start_date: startDate,
      end_date: endDate,
    })
      .unwrap()
      .then((res) => {
        setTrafficReport(res);
      })
      .catch((err) => console.log(err));
  }, [vehicleValue, selectedHotel, pathwayReport, allHotels]);

  useEffect(() => {
    let startDate: any = "";
    let endDate: any = "";

    if (value) {
      startDate = value[0]?.format("YYYY-MM-DD");
      endDate = value[1]?.format("YYYY-MM-DD");
    }

    AccommodationReport({
      id: selectedHotel || allHotels[0]?.id,
      start: endDate,
      end: startDate,
    })
      .unwrap()
      .then((res) => {
        sethotelReport(res);
      });
  }, [value, selectedHotel, AccommodationReport, allHotels]);

  return (
    <section>
      <div className={styles.container}>
        <PageHeader
          mainTitle="Accommodation"
          secondTitle=""
          containerStyle="mt-4"
        />
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <RangePicker value={value} onChange={setValue} />

          <Select
            showSearch
            placeholder="Select a Hotel"
            optionFilterProp="children"
            onChange={onChange}
            onSearch={onSearch}
            value={selectedHotel || allHotels[0]?.id}
            filterOption={filterOption}
            options={allHotels?.map((hotel: any) => ({
              value: hotel.id,
              label: hotel.name,
            }))}
          />
        </div>

        <Table
          dataSource={[hotelReport]}
          style={{ marginTop: "20px" }}
          pagination={false}
          sticky={{ offsetHeader: 0 }}
        >
          <Column
            title="Total Nights"
            dataIndex="total_nights"
            key="room_type"
          />
          <Column
            title="Total Rooms"
            dataIndex="total_rooms"
            key="room_count"
          />
          <Column title="Total Pax" dataIndex="total_pax" key="room_count" />
          <Column
            title="Total Cost"
            dataIndex="total_cost"
            key="room_count"
            className="leftColumn"
          />
        </Table>

        <Table
          dataSource={hotelReport?.room_types?.map((item: any) => ({
            ...item,
            basis: item?.basis.toUpperCase(),
          }))}
          style={{ marginTop: "20px" }}
          pagination={false}
          sticky={{ offsetHeader: 0 }}
        >
          <Column title="Rooms Type" dataIndex="room_type" key="room_type" />
          <Column title="Basis" dataIndex="basis" key="basis" />
          <Column title="Room Count" dataIndex="room_count" key="room_count" />
          <Column
            title="Cost/PP/N"
            dataIndex="cost_PP_night"
            key="room_count"
            className="leftColumn"
          />
        </Table>

        <div className="d-flex justify-content-between mt-4">
          <PageHeader
            mainTitle="Tours"
            secondTitle=""
            containerStyle={styles.vehicleContainer}
          />
          <RangePicker value={vehicleValue} onChange={setVehicleValue} />
        </div>

        <Table
          dataSource={trafficReport?.report?.map((item: any) => ({
            ...item,
            tour: item?.name,
            pax: item?.total_pax,
            currencyName: item?.currency__name,
            totalCost: item?.total_cost,
          }))}
          style={{ marginTop: "20px" }}
          pagination={false}
          sticky={{ offsetHeader: 0 }}
        >
          <Column title="Tour" dataIndex="tour" key="tourName" />
          <Column
            title="Currency Name"
            dataIndex="currencyName"
            key="currency__name"
          />
          <Column
            title="Total Cost"
            dataIndex="totalCost"
            key="total_cost"
            sorter={(a: any, b: any) => a.total_cost - b.total_cost}
            sortDirections={["descend", "ascend"]}
          />
          <Column
            title="PAX"
            dataIndex="pax"
            key="room_count"
            className="leftColumn"
            sorter={(a: any, b: any) => a.total_pax - b.total_pax}
            sortDirections={["descend", "ascend"]}
          />
        </Table>

        <div className="d-flex justify-content-between mt-4 align-items-center">
          <PageHeader
            mainTitle="Transfers"
            secondTitle=""
            containerStyle="mt-4"
          />
          <RangePicker value={transferDate} onChange={setTransferDate} />
        </div>
        <Table
          dataSource={vehicleReportData?.report?.map((item: any) => ({
            ...item,
            name: item?.trip_name__name,
            currencyName: item?.vehicle_fees_currency__name,
            totalCost: item?.total_fees,
            totalRides: item?.total_rides,
          }))}
          style={{ marginTop: "20px" }}
          pagination={false}
          sticky={{ offsetHeader: 0 }}
        >
          <Column
            title="Name"
            dataIndex="name"
            key="driverName"
            sorter={(a: any, b: any) => (a.trip_name > b.trip_name ? 1 : -1)}
            sortDirections={["descend", "ascend"]}
          />
          <Column
            title="Currency Name"
            dataIndex="currencyName"
            key="currency__name"
          />
          <Column
            title="Total Cost"
            dataIndex="totalCost"
            key="total_cost"
            sorter={(a: any, b: any) => a.total_fees - b.total_fees}
            sortDirections={["descend", "ascend"]}
          />
          <Column
            title="Total Rides"
            dataIndex="totalRides"
            key="room_count"
            className="leftColumn"
          />
        </Table>
      </div>
    </section>
  );
}
