import { Dispatch, SetStateAction, useState, useEffect } from "react";
import TableForm from "components/TableForm";
import { useGetArrivalDataMutation } from "apis/services/arrival";
import { useSearchParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setFiltered, setArrivalsCount } from "reducers/appReducer";
import FileAttachment from "components/FileAttachment";
import { columns } from "./DUMMY";

export default function ArrivalListTable({
  setIsGuestsModalVisible,
}: {
  setIsGuestsModalVisible: Dispatch<SetStateAction<boolean>>;
}) {
  const [searchParams] = useSearchParams();
  const [data, setData] = useState([{}]);
  const [paginationCount, setPaginationCount] = useState(0);
  const [editingKey, setEditingKey] = useState("");
  const [getArrivalData] = useGetArrivalDataMutation();
  const [rowId, setRowId] = useState([""]);
  const dispatch = useDispatch();
  const searchTerm = searchParams.get("search") ?? "";
  const handleOpenGuestsModal = () => {
    setIsGuestsModalVisible((prev) => !prev);
  };

  const formatDate = (dateStr: string) => {
    const dateParts = dateStr.split("-");
    const year = dateParts[0];
    const month = new Date(`${dateStr}T00:00:00`).toLocaleString("en-US", {
      month: "long",
    });
    const day = dateParts[2];
    return `${day} ${month} ${year}`;
  };

  const GetArrivalData = (params: any) => {
    getArrivalData(params)
      .unwrap()
      .then((values) => {
        setPaginationCount(values.count);

        const tempArr = values.results.map((element: any) => ({
          key: element.id.toString(),
          empty: "",
          arrivalDate: formatDate(element?.arrival_date),
          leaveDate: element?.leave_date && formatDate(element?.leave_date),
          agentFileNum: element?.agent_file_number,
          clientName: element?.guest_name,
          fileNum: element?.file_number,
          isNew: element?.new_arrival_check,
          isHandled: element?.is_handled,
          noAdultGuest: element?.number_of_adults,
          noChildGuest: element?.number_of_kids,
          flightReference: element?.flight_arrival[0]?.flight_reference,
          date: element?.flight_arrival[0]?.date,
          flightNum: element?.flight_arrival[0]?.flight_number,
          from: element?.flight_arrival[0]?.departure.name,
          to: element?.flight_arrival[0]?.destination.name,
          departureTime: element?.flight_arrival[0]?.departure_time,
          arrivalTime: element?.flight_arrival[0]?.arrival_time,
          attachments: element?.arrival_attachments[0]?.attachment && (
            <FileAttachment text="" />
          ),
        }));
        setData(tempArr);
        dispatch(setArrivalsCount(values.count));
        const id = tempArr.map((id) => id.key);
        setRowId(id);
      })
      .catch((err) => console.log(err, "err"))
      .finally(() => {});
  };

  useEffect(() => {
    const startDateParam = searchParams.get("startDate")
      ? searchParams.get("startDate")
      : undefined;
    const endDateParam = searchParams.get("endDate")
      ? searchParams.get("endDate")
      : undefined;

    const newArrivalParam =
      searchParams.get("isNewArrival") === "true" ? true : undefined;

    GetArrivalData({
      arrival_date_after: startDateParam,
      arrival_date_before: endDateParam,
      new_arrival: newArrivalParam,
      search: searchTerm,
      page: searchParams.get("arrivalTable") || "1",
      page_size: 5,
    });

    dispatch(setFiltered(false));
  }, [searchParams, paginationCount]);

  return (
    <TableForm
      data={data}
      setData={setData}
      columns={columns}
      editingKey={editingKey}
      deleteActionText
      setEditingKey={setEditingKey}
      navigation="/Dashboard/reservation-details?id="
      tourPathMethod={handleOpenGuestsModal}
      title="Arrival_table"
      fetchData={() => {}}
      urlId={rowId}
      paginationCount={paginationCount}
      paginationName="arrivalTable"
    />
  );
}
