function getDateFromTripAndFlight(data: any) {
  let results = "";

  if (data.type === "flight") {
    results = data?.flight?.date;
  } else {
    results = data?.date;
  }

  return results;
}

function filterData(data: any, dateToCompare: any) {
  let result = false;

  if (data.type === "flight") {
    result = data?.flight?.date === dateToCompare;
  } else {
    result = data?.date === dateToCompare;
  }

  return result;
}

export default function preparePathway(data: any[]) {
  const dates = Array.from(
    new Set(data.map((e) => getDateFromTripAndFlight(e)))
  );

  const formatTime = (timeStr: string) => {
    const [hours, minutes, seconds] = timeStr.split(":");
    const hour = parseInt(hours, 10);
    const minute = parseInt(minutes, 10);
    const isPM = hour >= 12;
    const formattedHour = hour % 12 || 12; // Convert 0 to 12
    const formattedMinutes = minute.toString().padStart(2, "0");
    const period = isPM ? "pm" : "am";
    return `${formattedHour}:${formattedMinutes} ${period}`;
  };

  const pathWayBasedOnDates = dates.map((dt, index) => ({
    id: `${index}-ee`,
    date: dt,
    mergedPathwayData: [],
    pathwayData: data
      .filter((item) => filterData(item, dt))
      .map((ele, innerIndex) => ({
        ...ele,
        key: `${index}-${innerIndex} path_data`,
        pathway: ele?.name?.name,
        flight: ele?.name?.name,
        date: ele?.flight?.date || ele?.date,
        flightNum: ele?.flight?.flight_number,
        names: ele?.flight?.member?.name,
        fileNum: ele?.arrival?.file_number,
        agentFileNum: ele?.arrival?.agent_file_number,
        from: ele?.flight?.departure?.name,
        to: ele?.flight?.destination?.name,
        departureTime: ele?.flight?.departure_time,
        arrivalTime: ele?.flight?.arrival_time,
        adultNum: ele?.adult,
        childNum: ele?.child,
        pickUp: ele?.pick_up?.hotel_cruise?.name,
        dropTo: ele?.drop_off,
        ticketPrice: ele?.price,
        guideInVisits: ele?.guide?.username,
        guideFees: `${ele?.guide?.fee} ${ele?.guide?.fee_currency}`,
        leaderName: ele?.leader?.username,
        leaderFees: `${ele?.leader?.fee} ${ele?.leader?.fee_currency}`,
        driverName: ele?.transportation?.driver_name,
        vehicle: ele?.transportation?.plate_number,
        vehicleFees: `${ele?.transportation?.vehicle_fees} ${ele?.transportation?.vehicle_fees_currency}`,
        driverFees: `${ele?.transportation?.vehicle_fees} ${ele?.transportation?.vehicle_fees_currency}`,
        visaFees: `${ele?.visa_price} ${ele?.visa_purchase_currency?.name}`,
        note: ele?.note,
        city: ele?.city?.name,
        time: formatTime(ele?.time),
      })),
  }));

  return pathWayBasedOnDates;
}

export const mergeRows = (rowsToBeMerged: any, weekDays: any) => {
  const arrForMerge: any[] = [];

  rowsToBeMerged.forEach((row: any) => {
    const rowDate = row.date;
    const rowIds = [...row.ids];
    if (rowIds.length > 1) {
      const copyArr = [...weekDays];
      const indexOfWeekDay = copyArr.findIndex(
        (weekDay) => weekDay.date === rowDate
      );
      const pathwayDataToBeMerged = copyArr[
        indexOfWeekDay
      ]?.pathwayData?.filter((pth: any) => {
        return rowIds.includes(pth?.id);
      });

      const pathwayDataNotToBeMerged = copyArr[
        indexOfWeekDay
      ].pathwayData.filter((pth: any) => {
        return !rowIds.includes(pth?.id);
      });

      copyArr[indexOfWeekDay] = {
        ...copyArr[indexOfWeekDay],
        pathwayData: [...pathwayDataNotToBeMerged],
        mergedPathwayData: [
          ...copyArr[indexOfWeekDay].mergedPathwayData,
          [...pathwayDataToBeMerged],
        ],
      };

      arrForMerge.push(copyArr[indexOfWeekDay]);
    }
  });
};
