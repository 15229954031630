import Text from "components/general/Text";
import { Form } from "antd";
import { useNavigate } from "react-router-dom";
import TextInput from "components/inputs/TextInput";
import Button from "components/general/Button";
// import { Link } from "react-router-dom";
import styles from "./styles.module.scss";

export default function ForgotPassword() {
  const navigate = useNavigate();
  return (
    <>
      <Text className={styles.title}>Forgot password</Text>
      <Text className={styles.secondaryTitle}>
        Enter your email address to change your account password. We will send
        you a password reset email.
      </Text>
      {/* <Text className={styles.secondaryTitle}>
        We&rsquo;ve sent a verification code to your email
      </Text> */}
      <Form
        className={styles.form}
        autoComplete="off"
        autoCorrect="off"
        onFinish={(val) => {
          navigate("/reset-password", { state: { email: val } });
        }}
      >
        <Form.Item name="email" rules={[{ required: true }, { type: "email" }]}>
          <TextInput i18nLabelKey="EMAIL" />
        </Form.Item>
        {/* <div className={styles.resendCode}>
          <Text className={styles.text}>Didn’t Receive Code?</Text>
          &nbsp;
          <Link className={styles.link} to="/">
            Resend Code
          </Link>
        </div> */}

        <Button btnClassName={styles.submitBtn} type="submit">
          Send Reset email
        </Button>
      </Form>
    </>
  );
}
