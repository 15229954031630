import { Outlet, useRoutes } from "react-router-dom";
import SidebarLayout from "components/wrappers/SidebarLayout";

// Main Screens Imports
import Home from "pages/Home";
import Users from "pages/Users";
import Login from "pages/Auth/Login";
import AuthLayout from "components/wrappers/AuthLayout";
import ForgotPassword from "pages/Auth/ForgotPassword";
import ResetPassword from "pages/Auth/ResetPassword";
import SetNewPassword from "pages/Auth/SetNewPassword";
import ProtectedOutlet from "components/wrappers/ProtectedOutlet";
import AddNewArrival from "pages/AddNewArrival";
import Reports from "pages/Reports";
import ReservationDetails from "pages/ReservationDetails";
import Pathways from "pages/Pathways";
import Accommodation from "pages/Accommodation";
// End Main Screens Imports

export default function useGenerateRoutes() {
  const routes = useRoutes([
    {
      path: "/",
      element: (
        <AuthLayout>
          <Outlet />
        </AuthLayout>
      ),
      children: [
        {
          path: "",
          element: <Login />,
        },
        {
          path: "forgot-password",
          element: <ForgotPassword />,
        },
        {
          path: "reset-password",
          element: <ResetPassword />,
        },
        {
          path: "set-new-password",
          element: <SetNewPassword />,
        },
      ],
    },
    {
      path: "/Dashboard",
      element: (
        <SidebarLayout>
          <ProtectedOutlet />
        </SidebarLayout>
      ),
      children: [
        // Main Screens
        {
          path: "",
          element: <Home />,
        },
        {
          path: "users",
          element: <Users />,
        },
        {
          path: "add-new-arrival",
          element: <AddNewArrival />,
        },

        {
          path: "reports",
          element: <Reports />,
        },
        {
          path: "reservation-details",
          element: <ReservationDetails />,
        },
        {
          path: "pathways",
          element: <Pathways />,
        },
        {
          path: "accommodation",
          element: <Accommodation />,
        },
        // End Main Screens
      ],
    },
  ]);

  return routes;
}
