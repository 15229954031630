import Text from "components/general/Text";
import Button from "components/general/Button";
import COLORS from "constants/Colors";
import { IoMdClose } from "react-icons/io";
import { ModalWrapperProps } from "./types";
import styles from "./styles.module.scss";

export default function ModalWrapper({
  children,
  size,
  headerTitle,
  headerTitleJustify = "center",
  isFooterHidden = false,
  onConfirm,
  isVisible = false,
  setIsVisible,
}: ModalWrapperProps) {
  if (!isVisible) return null;

  return (
    <div className={styles.modal}>
      <div className={`${styles.modalBox} ${styles[size]}`}>
        {/* Modal Header */}
        <div
          className={`${styles.modalContainer} ${styles.header} ${styles[headerTitleJustify]}`}
        >
          <div
            className={`${styles.headerTitle} ${styles[headerTitleJustify]}`}
          >
            <Text>{headerTitle}</Text>
          </div>
          <Button
            isOutline
            color="Neutral0"
            onClick={() => setIsVisible(false)}
          >
            <IoMdClose style={{ color: COLORS.Neutral700 }} size={32} />
          </Button>
        </div>
        {/* Modal Body */}
        <div className={`${styles.modalContainer} ${styles.body}`}>
          {children}
        </div>
        {/* Modal Footer */}
        {!isFooterHidden && (
          <div className={`${styles.modalContainer} ${styles.footer}`}>
            <Button onClick={onConfirm}>Confirm</Button>
          </div>
        )}
      </div>
    </div>
  );
}
