import { useState, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import {
  useGetArrivalDataByIdMutation,
  useGetMemberDataMutation,
  useGetSingleMemberMutation,
} from "apis/services/arrival";
import TableForm from "components/TableForm";
import TableMockup from "./TableMockup";
import { ColumnTypes } from "./types";

const columns: (ColumnTypes[number] & {
  editable?: boolean;
  dataIndex: string;
})[] = [
  {
    title: "",
    dataIndex: "empty",
    key: "empty",
    width: 20,
    fixed: "left",
  },
  {
    title: "Name",
    dataIndex: "name",
    width: 188,
    editable: true,
  },
  {
    title: "Maturity",
    dataIndex: "maturity",
    width: 188,
    editable: true,
  },
  {
    title: "Age",
    dataIndex: "age",
    width: 188,
    editable: true,
  },
  {
    title: "Nationality",
    dataIndex: "nationality",
    width: 188,
    editable: true,
  },
  {
    title: "Gender",
    dataIndex: "gender",
    width: 188,
    editable: true,
  },
  {
    title: "Comments",
    dataIndex: "comment",
    width: 100,
    editable: true,
  },
];

type MemberData = any;
type MembersTableProps = {
  id: any;
};

const getEmptyObj = (key: number) => {
  return {
    key: key.toString(),
    name: ``,
    age: 0,
    maturity: "",
    nationality: "",
    gender: "",
    packageType: "",
    comment: "",
  };
};

export default function Members({ id }: MembersTableProps) {
  const [editingKey, setEditingKey] = useState("");
  const [customArr, setCustomArr] = useState<MemberData>([]);
  const [paginationCount, setPaginationCount] = useState(0);
  const [searchParams] = useSearchParams();
  // const [getSingleMemberData, { isLoading: isSingleMemberDataLoading }] =
  //   useGetSingleMemberMutation();
  const [getMemberData] = useGetMemberDataMutation();
  const [getArrivalData, { isLoading: isGetArrivalDataLoading }] =
    useGetArrivalDataByIdMutation();

  const handleAddNewRow = () => {
    setCustomArr((prev: any) => [getEmptyObj(prev.length), ...prev]);
    setEditingKey(`${customArr.length}`);
  };

  const urlId = id;

  const fetchMembersData = async (param: any) => {
    try {
      const arrivalData = await getArrivalData(id).unwrap();
      const url = new URL(window.location.href);
      url.searchParams.append(
        "Filearrival_date",
        arrivalData?.data?.arrival_date
      );
      url.searchParams.append("Fileleave_date", arrivalData?.data?.leave_date);

      window.history.pushState({}, "", url);

      getMemberData(param)
        .unwrap()
        .then((data: any) => {
          setPaginationCount(data.count);
          const formattedMembersData = data.results.map((member: any) => ({
            key: member.id.toString(),
            name: member.name,
            age: member.age,
            arrival: urlId,
            maturity: member.maturity,
            nationality: member.nationality,
            gender: member.gender,
            comment: member.comment,
          }));
          setCustomArr(formattedMembersData);
        });
      // setCustomArr(formattedMembersData);

      // const memberIds = arrivalData?.data?.member_arrival?.map(
      //   (member: { id: any }) => member.id
      // );
      // const memberPromises = memberIds?.map((memberId: number) =>
      //   getSingleMemberData(memberId).unwrap()
      // );
      // const membersData = await Promise.all(memberPromises);
      // const formattedMembersData = membersData?.map((member) => ({
      //   key: member.id.toString(),
      //   name: member.name,
      //   age: member.age,
      //   arrival: urlId,
      //   maturity: member.maturity,
      //   nationality: member.nationality,
      //   gender: member.gender,
      //   comment: member.comment,
      // }));
      // setCustomArr(formattedMembersData);
    } catch (error) {
      console.error("Error fetching members data:", error);
    }
  };

  useEffect(() => {
    fetchMembersData({
      arrival: id,
      page: searchParams.get("memberTable") ?? "1",
      page_size: 5,
    });
  }, [id, searchParams.get("memberTable")]);

  return (
    <div>
      <TableMockup
        title="Members Info"
        btn1Title="Add New Member"
        onClickBtn1={handleAddNewRow}
        isBtnDisabled={!!editingKey || isGetArrivalDataLoading}
        handleCheck=""
      >
        <TableForm
          data={customArr}
          setData={setCustomArr}
          columns={columns}
          editingKey={editingKey}
          setEditingKey={setEditingKey}
          tourPathMethod={() => {}}
          title="members"
          fetchData={fetchMembersData}
          urlId={urlId}
          setIsLoading={isGetArrivalDataLoading}
          paginationCount={paginationCount}
          paginationName="memberTable"
        />
      </TableMockup>
    </div>
  );
}
