import dayjs from "dayjs";
import { useSearchParams } from "react-router-dom";
import { useEffect, useState } from "react";
import moment from "moment";
import { Pagination, Select, Space } from "antd";
import preparePathway, {
  mergeRows,
} from "components/AddNewArrivalTables/Utils/preparePathway";
import PathwayRow from "components/PathwayRow";
import ModalComponent from "components/ModalComponent";
import Icon from "components/general/Icon";
import Text from "components/general/Text";
import MainTableLayout from "components/wrappers/MainTableLayout";
import DIMENSIONS from "constants/Dimensions";
import DUMMY_DATA from "DATA";
import {
  useGetArrivalDataByIdMutation,
  useGetTrafficDataQuery,
  useLazyGetTrafficDataQuery,
  usePostTrafficMergeDataMutation,
} from "apis/services/arrival";
import ReservationDetailsContextProvider from "contexts/reservationDetailsContext";
import styles from "./styles.module.scss";

const isMobile = DIMENSIONS.windowWidth < 480;
const columnWidth = !isMobile ? "125px" : "90px";
const rowMinHeight = "150px";

interface PathwayData {
  id: number;
  date: string | null;
  merged?: boolean;
  transportation: { id: string; driver_name: string };
  [key: string]: any;
}

function getEmptyObj(dayIndex: number, length: number, defaultValues: object) {
  return {
    ...defaultValues,
    key: `${dayIndex}-${length} path_data`,
    id: `${length * 1000}`,
    pathway: "",
    time: "",
    city: "",
    adultNum: 4,
    childNum: 2,
    pickUp: "",
    drop: "",
    ticketNum: "",
    ticketPrice: "",
    guideInVisits: "",
    leaderName: "",
    leaderFees: "",
    driverName: "",
    vehicle: "",
    vehicleFees: "",
    note: "",
  };
}

export default function Pathways() {
  const [searchParams, setSearchParams] = useSearchParams();
  const id = searchParams.get("id");
  const [arrivalDatawithId, { data: arrivalData }] =
    useGetArrivalDataByIdMutation();
  const [postMergeTrafficData] = usePostTrafficMergeDataMutation();
  const [getAllTraficData] = useLazyGetTrafficDataQuery();

  const [subTitleText, setSubTitleText] = useState("");
  const [pageNumber, setPageNumber] = useState(1);
  const [weekDays, setWeekDays] = useState<any[]>([]);
  const [newPathwayData, setNewPathwayData] = useState<any[]>([]);
  const [rowsToBeMerged, setRowsToBeMerged] = useState<any[]>([]);
  const [isMergeLoading, setIsMergeLoading] = useState(false);
  const [isVisible, setIsVisible] = useState(false);
  const [driverOptions, setDriverOptions] = useState<any[]>([]);
  const [selectedValue, setSelectedValue] = useState<any>();
  const [selectedIds, setSelectedIds] = useState<any>();
  const [arrforMergeHandler, setArrForMergeHandler] = useState<any>();
  const handleChange = (value: string) => {
    setSelectedValue(value);
  };

  const isVisibleHander = () => {
    setIsVisible((prevState) => !prevState);
  };

  const filterType = searchParams.get("filterType");
  const [startDateParam, setStartDateParam] = useState(
    filterType === "date"
      ? searchParams.get("startDate")
      : dayjs().format("YYYY-MM-DD")
  );
  const [endDateParam, setEndDataParam] = useState(
    filterType === "date"
      ? searchParams.get("endDate")
      : dayjs(startDateParam).add(6, "day").format("YYYY-MM-DD")
  );
  const searchTerm = searchParams.get("search");

  const startDate =
    filterType === "date"
      ? searchParams.get("startDate") ?? startDateParam
      : "";
  const endDate =
    filterType === "date" ? searchParams.get("endDate") ?? endDateParam : "";

  const { data: trafficData } = useGetTrafficDataQuery({
    date_after: startDate,
    date_before: endDate,
    page_size: 10000,
    search: searchTerm ?? "",
    page: pageNumber ?? "1",
  });

  const [editingKey, setEditingKey] = useState("");
  const [isAddNewRowLoading, setIsAddNewRowLoading] = useState(false);

  const handleAddNewRow = () => {
    if (!weekDays.length) {
      setEditingKey(`${0}-${0} path_data`);
      setWeekDays([
        {
          id: `${0}-ee`,
          date: moment().locale("en").format("DD-MM-YYYY"),
          mergedPathwayData: [
            getEmptyObj(0, 0, {
              fileNum: arrivalData?.file_number,
              agentFileNum: arrivalData?.agentFileNum,
            }),
          ],
          pathwayData: [
            getEmptyObj(0, 0, {
              fileNum: arrivalData?.file_number,
              agentFileNum: arrivalData?.agentFileNum,
            }),
          ],
        },
      ]);
    } else {
      setEditingKey(`${0}-${weekDays[0]?.pathwayData.length} path_data`);

      setWeekDays((prev) => {
        const pArr = [...prev];

        pArr.splice(0, 1, {
          ...pArr[0],
          pathwayData: [
            ...pArr[0].pathwayData,
            getEmptyObj(0, pArr[0].pathwayData.length, {
              fileNum: arrivalData?.file_number,
              agentFileNum: arrivalData?.agent_file_number,
            }),
          ],
        });

        return [...pArr];
      });
    }
  };

  const handleMergeRow = () => {
    isVisibleHander();
    const idsToBeAdded: any[] = [];
    rowsToBeMerged?.forEach((row) => {
      if (Array.isArray(row.ids)) {
        idsToBeAdded.push(...row.ids);
      }
    });

    const arrForMerge: any[] = [];

    rowsToBeMerged.forEach((row) => {
      const rowDate = row.date;
      const rowIds = [...row.ids];
      if (rowIds.length > 1) {
        const copyArr = [...weekDays];
        const indexOfWeekDay = copyArr.findIndex(
          (weekDay) => weekDay.date === rowDate
        );
        const pathwayDataToBeMerged = copyArr[
          indexOfWeekDay
        ]?.pathwayData?.filter((pth: any) => {
          return rowIds.includes(pth?.id);
        });

        const pathwayDataNotToBeMerged = copyArr[
          indexOfWeekDay
        ].pathwayData.filter((pth: any) => {
          return !rowIds.includes(pth?.id);
        });

        copyArr[indexOfWeekDay] = {
          ...copyArr[indexOfWeekDay],
          pathwayData: [...pathwayDataNotToBeMerged],
          mergedPathwayData: [
            ...copyArr[indexOfWeekDay].mergedPathwayData,
            [...pathwayDataToBeMerged],
          ],
        };

        arrForMerge.push(copyArr[indexOfWeekDay]);
      }
    });

    const mergePathwayData = arrForMerge?.map(
      (value) => value?.mergedPathwayData
    );

    const flattenedArray = mergePathwayData.flat(2);

    const uniqueDriverMap: { [id: string]: string } = {};

    flattenedArray.forEach((item: { transportation: any }) => {
      const { id } = item.transportation;
      const driver: string = item.transportation.driver_name;

      if (!(id in uniqueDriverMap)) {
        uniqueDriverMap[id] = driver;
      }
    });

    const newDriverOptions: { value: string; label: string }[] = Object.entries(
      uniqueDriverMap
    ).map(([id, driver]) => ({
      value: id,
      label: driver,
    }));

    setDriverOptions(newDriverOptions);
    setSelectedIds(idsToBeAdded);
    setArrForMergeHandler(arrForMerge);
  };

  // useEffect(() => {
  //   console.log("1");
  //   const startDate = searchParams.get("startDate") || new Date();
  //   setSubTitleText(
  //     `From ${dayjs(startDate).format("DD MMM")} To ${dayjs(startDate)
  //       .add(6, "day")
  //       .format("DD MMM")}`
  //   );
  // }, [searchParams]);

  useEffect(() => {
    if (trafficData?.results) {
      const sortedData = [...trafficData.results].sort((a, b) => {
        const dateA = a.date || a.flight?.date || null;
        const dateB = b.date || b.flight?.date || null;

        if (!dateA && !dateB) return 0;
        if (!dateA) return 1;
        if (!dateB) return -1;

        return new Date(dateB).getTime() - new Date(dateA).getTime();
      });

      const preparedPathwayData = preparePathway(sortedData);

      const formattedWeekDays = preparedPathwayData.map((e) => {
        return {
          ...e,
          date: dayjs(e.date).format("DD MMM"),
        };
      });

      setWeekDays(formattedWeekDays);

      const groupedByMergeId: { [key: string]: PathwayData[] } = {};

      preparedPathwayData.forEach((day) => {
        day.pathwayData.forEach((pathway) => {
          const mergeId = pathway.merged ? JSON.stringify(pathway.date) : null;
          if (mergeId) {
            if (!groupedByMergeId[mergeId]) {
              groupedByMergeId[mergeId] = [];
            }
            groupedByMergeId[mergeId].push(pathway);
          }
        });
      });

      const objectsWithSameMerged = Object.values(groupedByMergeId).filter(
        (group): group is PathwayData[] =>
          group.length > 1 && group[0].merged !== null
      );

      const updatedWeekDays = [...formattedWeekDays];

      objectsWithSameMerged.forEach((group: any) => {
        const firstMergedRowDate = group[0].date;
        const dateObj = new Date(firstMergedRowDate);
        const formattedDate = dateObj.toLocaleDateString("en-GB", {
          day: "2-digit",
          month: "short",
        });

        const matchingWeekdayIndex = updatedWeekDays.findIndex(
          (weekday) => weekday.date === formattedDate
        );

        if (matchingWeekdayIndex !== -1) {
          const matchingWeekday = { ...updatedWeekDays[matchingWeekdayIndex] };

          // Remove merged data from the original pathwayData array
          matchingWeekday.pathwayData = matchingWeekday.pathwayData.filter(
            (rowData) =>
              !group.some(
                (mergedRow: { id: any }) => mergedRow.id === rowData.id
              )
          );

          updatedWeekDays[matchingWeekdayIndex] = matchingWeekday;
        }
      });

      /* eslint-disable no-param-reassign */
      updatedWeekDays.forEach((weekday: any) => {
        const allMergedDataIds = weekday?.mergedPathwayData?.flatMap(
          (group: any) => group.map((rowData: { id: any }) => rowData.id)
        );
        weekday.pathwayData = weekday?.pathwayData?.filter(
          (rowData: any) => !allMergedDataIds?.includes(rowData?.id)
        );
      });
      /* eslint-disable no-param-reassign */

      setNewPathwayData(updatedWeekDays);
    }
  }, [trafficData?.results]);

  /* eslint-disable no-param-reassign */
  useEffect(() => {
    const groupedByMerged: { [key: string]: any[] } = {};

    weekDays.forEach((weekday) => {
      weekday.pathwayData.forEach((data: any) => {
        const mergedValue = data.merged !== null ? data.merged : null;
        if (mergedValue !== null) {
          if (!groupedByMerged[mergedValue]) {
            groupedByMerged[mergedValue] = [];
          }
          groupedByMerged[mergedValue].push(data);
        }
      });
    });

    const objectsWithSameMerged = Object.values(groupedByMerged).filter(
      (group) => group.length > 1 && group[0].merged !== null
    );

    objectsWithSameMerged.forEach((group) => {
      const firstMergedRowDate = group[0].date;
      const dateObj = new Date(firstMergedRowDate);
      const formattedDate = dateObj.toLocaleDateString("en-GB", {
        day: "2-digit",
        month: "short",
      });

      const matchingWeekdayIndex = weekDays.findIndex(
        (weekday) => weekday.date === formattedDate
      );

      if (matchingWeekdayIndex !== -1) {
        const matchingWeekday = { ...weekDays[matchingWeekdayIndex] };

        // Remove merged data from the original pathwayData array
        matchingWeekday.pathwayData = matchingWeekday.pathwayData.filter(
          (rowData: any) =>
            !group.some((mergedRow) => mergedRow.id === rowData.id)
        );

        // Check if the group has already been added to the mergedPathwayData
        const groupIds = group.map((rowData: any) => rowData.id);
        const isGroupAdded = matchingWeekday.mergedPathwayData.some(
          (existingGroup: any[]) =>
            existingGroup.every((rowData: any) => groupIds.includes(rowData.id))
        );

        if (!isGroupAdded) {
          matchingWeekday.mergedPathwayData.push(group);
        }

        const updatedWeekDays = [...weekDays];
        updatedWeekDays[matchingWeekdayIndex] = matchingWeekday;

        setNewPathwayData(updatedWeekDays);
      }
    });

    // Clean up duplicated entries in pathwayData
    const updatedWeekDays = [...weekDays];
    updatedWeekDays.forEach((weekday) => {
      const allMergedDataIds = weekday.mergedPathwayData.flatMap(
        (group: any[]) => group.map((rowData: any) => rowData.id)
      );
      weekday.pathwayData = weekday.pathwayData.filter(
        (rowData: any) => !allMergedDataIds.includes(rowData.id)
      );
    });

    setNewPathwayData(updatedWeekDays);
    console.log(updatedWeekDays);
  }, [weekDays]);
  /* eslint-disable no-param-reassign */

  const confirmMerge = () => {
    setIsMergeLoading(true);

    const requestData = {
      traffic_ids: selectedIds,
      transportation_id: selectedValue,
    };

    postMergeTrafficData(requestData)
      .unwrap()
      .then(() => {
        if (arrforMergeHandler.length) {
          getAllTraficData({
            date_after: startDateParam,
            date_before: endDateParam,
            page_size: 50,
            search: searchTerm ?? "",
            // page:1
          })
            .unwrap()
            .then(() => {
              isVisibleHander();
              setSelectedIds([]);
              setSelectedValue([]);
              window.location.reload();
            });
        } else {
          setIsMergeLoading(false);
          isVisibleHander();
          setSelectedIds([]);
          setSelectedValue([]);
        }
      });
  };

  const cancelMerge = () => {
    isVisibleHander();
  };
  return (
    <MainTableLayout
      title="Traffic"
      subTitle={
        searchParams.has("startDate") &&
        `From ${
          (filterType === "date" &&
            searchParams.get("startDate") &&
            dayjs(searchParams.get("startDate")).format("DD MMM")) ??
          dayjs(startDateParam).format("DD MMM")
        } To ${
          (filterType === "date" &&
            searchParams.get("endDate") &&
            dayjs(searchParams.get("endDate"))?.format("DD MMM")) ??
          dayjs(endDateParam).format("DD MMM")
        }`
      }
      mainBtnText=""
      mainBtnSuffix={<Icon name="arrow-down" size={24} />}
      mainBtnClassName={styles.btnStyle}
      secondaryBtnClassName={styles.btnStyle}
      secondaryBtnText="Merge Group"
      mainBtnOnClick={handleAddNewRow}
      mainBtnDisabled={!!editingKey}
      secondaryBtnOnClick={handleMergeRow}
      hideSearch={false}
    >
      <ReservationDetailsContextProvider arrivalId={id}>
        <ModalComponent
          isVisible={isVisible}
          centered
          setIsVisible={isVisibleHander}
          title="Merge Confirmation"
          modalClassName={styles.modalDriver}
        >
          <div className={styles.container}>
            <div className={styles.description}>
              You have to choose one driver for this merged group
            </div>

            <Space wrap className={styles.selectDriver}>
              <div className={styles.title}>Select the Driver</div>
              <Select
                defaultValue=""
                style={{ width: 400, height: 40 }}
                onChange={handleChange}
                title="Select the driver"
                options={driverOptions}
              />
            </Space>

            <div className={styles.btnHolder}>
              <div className={styles.lineBreak} />
              <button className={styles.btnConfirm} onClick={confirmMerge}>
                Confirm
              </button>
              <button className={styles.btnCancel} onClick={cancelMerge}>
                Cancel
              </button>
            </div>
          </div>
        </ModalComponent>
        <div>
          {/* Table Header */}
          <div className={`${styles.tableHeader}`}>
            <Text
              className={`${styles.dateColumn}`}
              style={{ width: columnWidth }}
            >
              Date
            </Text>

            <Text className={`${styles.pathwayColumn}`}>Traffic Type</Text>
          </div>
          {/* Table Rows */}
          {!isMergeLoading && !isAddNewRowLoading ? (
            <div>
              {newPathwayData?.length > 0 &&
                newPathwayData?.map((weekDayItem) => (
                  <PathwayRow
                    key={`${weekDayItem?.id}-key`}
                    date={weekDayItem?.date}
                    data={weekDayItem?.pathwayData}
                    mergedData={weekDayItem?.mergedPathwayData}
                    setRowsToBeMerged={setRowsToBeMerged}
                    columnWidth={columnWidth}
                    rowMinHeight={rowMinHeight}
                    editingKey={editingKey}
                    setEditingKey={setEditingKey}
                    showCheckBox
                    setIsAddNewRowLoading={setIsAddNewRowLoading}
                  />
                ))}
            </div>
          ) : (
            <div style={{ paddingBlock: 20, textAlign: "center" }}>
              <Text style={{ textAlign: "center" }}>Loading...</Text>
            </div>
          )}
        </div>
        {/* <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            padding: "10px 0px",
          }}
        >
          <button
            className={styles.btnStyle}
            onClick={() => {
              searchParams.delete("endDate");
              setSearchParams(searchParams);
              setEndDataParam(
                dayjs(endDateParam).add(7, "day").format("YYYY-MM-DD")
              );
            }}
          >
            Load Another Week!
          </button>
        </div> */}
      </ReservationDetailsContextProvider>
    </MainTableLayout>
  );
}
