// import Text from "components/general/Text";
import styles from "./styles.module.scss";
import { PageHeaderProps } from "./types";

export default function PageHeader({
  mainTitle,
  secondTitle,
  containerStyle,
}: PageHeaderProps) {
  return (
    <div className={`${containerStyle}`}>
      <h1 className={`${styles.mainTitle}`}>{mainTitle}</h1>
      <p className={`${styles.secondTitle}`}>{secondTitle}</p>
    </div>
  );
}
