import { Dispatch, SetStateAction, useEffect, useState } from "react";
import TableForm from "components/TableForm";
import { useSearchParams } from "react-router-dom";
import {
  columnsForFlight,
  columnsForTrip,
  columnsForMixedMerged,
} from "./columns";
import styles from "./styles.module.scss";

export default function PathwayRowTable({
  data,
  columns,
  editingKey,
  setEditingKey,
  isEditingRow,
  setIsAddNewRowLoading,
}: {
  data: any[];
  columns: any[];
  editingKey: string;
  setEditingKey: Dispatch<SetStateAction<string>>;
  isEditingRow: boolean;
  setIsAddNewRowLoading?: Dispatch<SetStateAction<boolean>>;
}) {
  const [searchParams, setSearchParams] = useSearchParams();
  const [tableData, setTableData] = useState([...data]);
  const [selectedPathway, setSelectedPathway] = useState("");
  const [selectedTrip, setSelectedTrip] = useState("");

  const allTypes: Set<string> = new Set();
  tableData.forEach((item) => {
    allTypes.add(item.type);
  });

  useEffect(() => {
    searchParams.set("pathwayTrip", selectedTrip);
    setSearchParams(searchParams);
  }, [setSelectedTrip, selectedTrip, searchParams.has("pathwayTrip")]);

  const editingColumns = [
    columns[0],
    {
      title: "Type",
      dataIndex: "pathwayType",
      width: 188,
      editable: true,
    },
    ...(selectedPathway === "trip" ? columnsForTrip : columnsForFlight),
    // ...(selectedPathway === "trip"
    // ? columnsForTrip
    // : allTypes.size >= 2 && allTypes.has("flight") && allTypes.has("trip")
    // ? columnsForMixedMerged
    // : columnsForFlight),
  ];

  const getRowStyle = (): string => {
    if (isEditingRow) {
      if (selectedPathway === "trip") {
        return styles.tripRow;
      }
      return styles.flightRow;
    }

    if (allTypes.size === 1) {
      const type = tableData[0]?.type;
      if (type === "trip") {
        return styles.tripRow;
      }
      if (type === "flight") {
        return styles.flightRow;
      }
    } else if (
      allTypes.size >= 2 &&
      allTypes.has("flight") &&
      allTypes.has("trip")
    ) {
      return styles.flightAndTripRow;
    }

    return styles.defaultRowStyle;
  };

  // console.log(tableData);

  return (
    <div className={`${getRowStyle()}`}>
      <TableForm
        data={tableData}
        columns={isEditingRow ? editingColumns : columns}
        setData={setTableData}
        editingKey={editingKey}
        setEditingKey={setEditingKey}
        tourPathMethod={() => {}}
        deleteActionText
        title="pathway"
        fetchData={() => {}}
        urlId=""
        setSelectedPathway={setSelectedPathway}
        setSelectedTrip={setSelectedTrip}
        setIsLoading={setIsAddNewRowLoading}
      />
    </div>
  );
}
