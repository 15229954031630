import { useState } from "react";
import Button from "components/general/Button";
import Icon from "components/general/Icon";
import COLORS from "constants/Colors";
import styles from "./styles.module.scss";
import { ActionMenuButtonProps } from "./types";

export default function ActionMenuButton({
  rowIndex,
  disabled,
  setIndex,
  onClick,
  handleTourPath,
  deleteRowIndex,
  deleteRowAPI,
  handleEdit,
  // handleDelete,
  isFromPathwayPage,
}: ActionMenuButtonProps) {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const handleBtnClick = () => {
    onClick();
    setIsMenuOpen((prev) => !prev);
    if (!isMenuOpen) {
      setIndex(rowIndex);
    } else {
      setIndex(null);
    }
  };

  const handleClickEdit = () => {
    handleEdit();
    setIsMenuOpen(false);
  };

  const handleClickDelete = () => {
    // handleDelete(rowIndex);
    deleteRowAPI(deleteRowIndex, rowIndex);
    setIsMenuOpen(false);
    setIndex(null);
  };

  const showOnlyEdit = !!isFromPathwayPage;

  return (
    <div className={styles.mainBtn} style={{ position: "relative" }}>
      <Button
        btnClassName={`${styles.actionEditBtn}`}
        disabled={disabled}
        onClick={handleBtnClick}
        isOutline
      >
        <Icon name="more" size={23} color="IconGray2" />
      </Button>
      {isMenuOpen ? (
        <ul className={`${styles.floatingMenu}`}>
          <button onClick={handleClickEdit}>Edit</button>
          {!showOnlyEdit && (
            <button
              onClick={handleClickDelete}
              style={{ color: COLORS.MainDanger }}
            >
              Delete
            </button>
          )}
        </ul>
      ) : (
        ""
      )}
    </div>
  );
}
