import Text from "components/general/Text";
import { Form } from "antd";
import TextInput from "components/inputs/TextInput";
import Button from "components/general/Button";

// import showSuccessMsg from "utils/showSuccessMsg";
// import showToast from "utils/showToast";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import CustomToast from "components/general/CustomToast";
import styles from "./styles.module.scss";

export default function SetNewPassword() {
  const navigate = useNavigate();

  return (
    <>
      <Text className={styles.title}>Set new Password</Text>
      <Text className={styles.secondaryTitle}>
        Minimum 8 characters. At least one uppercase letter. One lowercase
        letter and one number.
      </Text>
      <Form
        className={styles.form}
        autoComplete="off"
        autoCorrect="off"
        onFinish={(val) => {
          // console.log(val);
          //   showSuccessMsg({ msg: "Password Is Set Successfully!!" });
          //   showToast({
          //     toastType: "success",
          //     msg: "Password Set Successfully!!",
          //   });
          toast(
            CustomToast({
              toastType: "success",
              title: "password",
              msg: "Successfully!!",
            }),
            {
              onClose: () => {
                navigate("/");
                // console.log("CLOSED");
              },
            }
          );
        }}
      >
        <Form.Item
          name="password"
          rules={[
            {
              required: true,
              message: "This Field Is Required",
            },
            {
              pattern: /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)[^\s]{8,}$/g,
              message: "Weak Password. Please follow the upper pattern!!",
            },
          ]}
        >
          <TextInput i18nLabelKey="PASSWORD" type="password" />
        </Form.Item>

        <Form.Item
          name="confirmPassword"
          dependencies={["password"]}
          rules={[
            {
              required: true,
              message: "This Field Is Required",
            },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue("password") === value) {
                  return Promise.resolve();
                }
                return Promise.reject(
                  new Error("The new password that you entered do not match!")
                );
              },
            }),
          ]}
        >
          <TextInput i18nLabelKey="CONFIRMPASSWORD" type="password" />
        </Form.Item>

        <Button btnClassName={styles.submitBtn} type="submit">
          Confirm
        </Button>
      </Form>
    </>
  );
}
