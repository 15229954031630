import React, { useEffect, useState, Dispatch, SetStateAction } from "react";
import { Checkbox } from "antd";
import Text from "components/general/Text";
import type { CheckboxChangeEvent } from "antd/es/checkbox";
import PathwayRowTable from "./PathwayRowTable";
import styles from "./styles.module.scss";
import {
  columnsForFlight,
  columnsForTrip,
  columnsForMixedMerged,
} from "./columns";

function formatDate(dateString: string) {
  const date = new Date(dateString);
  const options: Intl.DateTimeFormatOptions = {
    day: "numeric",
    month: "short",
  };
  return date.toLocaleDateString("en-GB", options);
}

export default function PathwayRow({
  date,
  data,
  mergedData,
  setRowsToBeMerged,
  columnWidth,
  rowMinHeight,
  editingKey,
  setEditingKey,
  showCheckBox,
  setIsAddNewRowLoading,
}: {
  date: string;
  data: any[];
  mergedData: any[];
  setRowsToBeMerged: Dispatch<SetStateAction<any[]>>;
  columnWidth: string;
  rowMinHeight: string;
  editingKey: string;
  setEditingKey: Dispatch<SetStateAction<string>>;
  showCheckBox: any;
  setIsAddNewRowLoading?: Dispatch<SetStateAction<boolean>>;
}) {
  const [displayDate, setDisplayDate] = useState(formatDate(date));

  useEffect(() => {
    setDisplayDate(formatDate(date));
  }, [date]);

  const columnsWithoutCheck = {
    title: "",
    dataIndex: "empty",
    key: "empty",
    width: 80,
    fixed: "left",
  };

  const columnsWithCheck = {
    ...columnsWithoutCheck,
    render: (_: any, record: any) => {
      const handleOnChange = (e: CheckboxChangeEvent) => {
        if (e.target.checked) {
          setRowsToBeMerged((prev) => {
            const copyArr = [...prev];
            const prevIndex = prev.findIndex((ele) => ele?.date === date);
            if (prevIndex !== -1) {
              copyArr.splice(prevIndex, 1, {
                date: prev[prevIndex].date,
                ids: [...prev[prevIndex].ids, record.id],
              });
              return [...copyArr];
            }
            copyArr.push({ date, ids: [record.id] });
            return [...copyArr];
          });
        } else {
          setRowsToBeMerged((prev) => {
            const copyArr = [...prev];
            const prevIndex = prev.findIndex((ele) => ele?.date === date);
            if (copyArr[prevIndex].ids.length > 1) {
              copyArr[prevIndex].ids.splice(
                copyArr[prevIndex].ids.findIndex(
                  (idEle: any) => idEle === record.id
                ),
                1
              );
              return [...copyArr];
            }
            copyArr.splice(prevIndex, 1);
            return [...copyArr];
          });
        }
      };
      return <Checkbox onChange={handleOnChange} />;
    },
  };

  const getColumns = (showCheck: boolean, type: string) => {
    return [
      showCheck ? columnsWithCheck : columnsWithoutCheck,
      ...(type === "mixed"
        ? columnsForMixedMerged
        : type === "trip"
        ? columnsForTrip
        : columnsForFlight),
    ];
  };

  return (
    <div className={`${styles.rowContainer}`}>
      <div
        className={`${styles.dateCell}`}
        style={{ width: columnWidth, minHeight: rowMinHeight }}
      >
        <Text>{displayDate}</Text>
      </div>
      <div
        className={` ${styles.row}`}
        style={{ minHeight: rowMinHeight, overflow: "hidden" }}
      >
        {mergedData?.map((tableData) => {
          if (!tableData) return;
          const allTypes: Set<string> = new Set();

          tableData?.forEach((item: any) => {
            allTypes.add(item.type);
          });
          const isMixed =
            allTypes.size >= 2 &&
            allTypes.has("flight") &&
            allTypes.has("trip");
          // eslint-disable-next-line consistent-return
          return (
            <PathwayRowTable
              key={`${tableData[0]?.merged}-keys.merged`}
              data={tableData}
              columns={getColumns(false, isMixed ? "mixed" : tableData[0].type)}
              isEditingRow={tableData.key === editingKey}
              editingKey={editingKey}
              setEditingKey={setEditingKey}
              setIsAddNewRowLoading={setIsAddNewRowLoading}
            />
          );
        })}
        {data?.length > 0 &&
          data?.map((tableData) => (
            <PathwayRowTable
              key={`${tableData?.id}-keys`}
              data={[tableData]}
              columns={getColumns(showCheckBox, tableData.type)}
              isEditingRow={tableData.key === editingKey}
              editingKey={editingKey}
              setEditingKey={setEditingKey}
              setIsAddNewRowLoading={setIsAddNewRowLoading}
            />
          ))}
      </div>
    </div>
  );
}
