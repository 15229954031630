import { useGetVehicleQuery } from "apis/services/arrival";
import GetSelectOptions from "utils/getSelectOptions";
import { DatePicker, Form, Input, Select, TimePicker } from "antd";
import UploadInput from "components/inputs/UploadInput";
import { useContext, useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import dayjs from "dayjs";
import { ReservationDetailsContext } from "contexts/reservationDetailsContext";
import { EditableCellProps } from "./types";

// prepare opts
const prepareOptionsForSelect = (obj: any, objItem: string) => {
  if (obj[objItem]?.name) {
    return { value: obj?.id, label: obj[objItem]?.name };
  }
  return { value: obj?.id, label: obj[objItem] };
};

// select component
function SelectWithContext({ selectType, onChange, ...rest }: any) {
  const [searchParams, setSearchParams] = useSearchParams();
  const [selectedPathwayLabel, setSelectedPathwayLabel] = useState<any>();
  const [driversData, setDriversData] = useState<any>();

  const [vehiclePage, setVehiclePage] = useState(1);
  const { data: vehicleData, isLoading: isVehicleLoading } = useGetVehicleQuery(
    {
      page: vehiclePage,
      search: searchParams.get("pathwayTrip"),
    }
  );

  // get all data from api
  useEffect(() => {
    setDriversData(vehicleData?.results);
  }, [vehicleData]);

  useEffect(() => {
    searchParams.set("payment", "paid");
    setSearchParams(searchParams);
  }, []);

  useEffect(() => {
    if (rest.id === "payment") {
      searchParams.set("payment", rest.value);
      setSearchParams(searchParams);
    }
  }, [rest.value]);

  let opts: any[] = [];
  const { data } = useContext(ReservationDetailsContext)!;

  switch (selectType) {
    case "pickUp":
      opts = data?.accommodation?.map((ele: any) =>
        prepareOptionsForSelect(ele, "hotel_cruise")
      );
      break;
    case "flightNum":
      opts = data?.flight?.map((ele: any) =>
        prepareOptionsForSelect(ele, "flight_number")
      );
      break;
    case "flight":
      opts = data?.trafficName?.map((ele: any) => ({
        value: ele?.id,
        label: ele?.name,
      }));
      break;
    case "pathway":
      opts = data?.trafficName?.map((ele: any) => ({
        value: ele?.id,
        label: ele?.name,
      }));
      break;
    case "driverName":
      opts = driversData?.map((ele: any) => ({
        label: ele?.driver_name,
        value: ele?.id,
      }));
      break;
    case "leaderName":
      opts = data?.userRoleLeader?.map((ele: any) => ({
        label: ele?.username,
        value: ele?.id,
      }));
      break;
    case "guideInVisits":
      opts = data?.userRoleGuide?.map((ele: any) => ({
        label: ele?.username,
        value: ele?.id,
      }));
      break;
    default:
      opts = GetSelectOptions(selectType) as object[];
      break;
  }

  return <Select options={opts} onChange={onChange} value={rest.value} />;
}

function EditableDateInput({ ...rest }) {
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    if (rest.id === "check_in" && rest.value) {
      searchParams.set(
        "week_days",
        dayjs(rest.value).format("dddd").toLocaleLowerCase()
      );
      setSearchParams(searchParams);
    }
  }, [rest.value]);

  return (
    <DatePicker
      value={typeof rest.value === "string" ? dayjs(rest.value) : rest.value}
      {...rest}
    />
  );
}

export default function EditableCell({
  editing,
  dataIndex,
  title,
  inputType,
  children,
  setSelectedPathway,
  setSelectedTrip,
  ...restProps
}: EditableCellProps) {
  const handleSelectChange = (value: any, option: any) => {
    if (dataIndex === "pathwayType" && setSelectedPathway)
      setSelectedPathway(value);
    if (
      (dataIndex === "pathway" || dataIndex === "flight") &&
      setSelectedTrip
    ) {
      setSelectedTrip(option.label);
    }
  };

  const getInputComponent = (input: string) => {
    switch (input) {
      case "select": {
        return (
          <SelectWithContext
            selectType={dataIndex}
            onChange={handleSelectChange}
          />
        );
      }
      case "dateInput": {
        return <EditableDateInput />;
      }
      case "timeInput": {
        return <TimePicker />;
      }
      case "fileInput": {
        return <UploadInput />;
      }
      default:
        return (
          <Input
            defaultValue={dataIndex === "nationality" ? "German" : ""}
            type={
              [
                "age",
                "reservation_number",
                "tours_days",
                "night",
                "rooms",
                "cost_PP_night",
                "visaFees",
                "visa",
                "adultNum",
                "childNum",
              ].includes(dataIndex)
                ? "number"
                : "text"
            }
          />
        );
    }
  };

  const inputNode = getInputComponent(inputType);

  return (
    <td {...restProps}>
      {editing ? (
        <Form.Item
          name={dataIndex}
          style={{ margin: 0 }}
          initialValue={dataIndex === "nationality" ? "German" : ""}
          rules={[
            {
              required: dataIndex !== "comment" && dataIndex !== "note",
              message: `Please Input ${title}!`,
            },
          ]}
        >
          {inputNode}
        </Form.Item>
      ) : (
        children
      )}
    </td>
  );
}
