const COLORS = {
  Neutral0: "#ffffff",
  Neutral100: "#f8f9fc",
  Neutral200: "#f1f3f9",
  Neutral300: "#e1e6ef",
  Neutral700: "#3f444d",
  Neutral800: "#23272f",
  Neutral900: "#1b1f27",
  Neutral1000: "#0a0d14",
  Primary100: "#A2926129",
  Primary700: "#A29261",
  Primary800: "#cbb670",
  Primary900: "#705e25",
  Success100: "#edfdf8",
  Success700: "#DAECE0",
  Success800: "#1A9946",
  Success900: "#066042",
  Warning100: "#fff8eb",
  Warning700: "#b25e09",
  Warning800: "#96530f",
  Warning900: "#80460d",
  Danger100: "#fef1f2",
  Danger700: "#D73D38",
  Danger800: "#ba2532",
  Danger900: "#981b25",
  TextPrimaryBlack: "#222425",
  TextPrimaryBlack48: "#2224257A",
  TextSecondaryBlack: "#1d2433cc",
  TextDisabledBlack: "#1d2433a6",
  TextPrimaryWhite: "#ffffff",
  TextSecondaryWhite: "#ffffffbf",
  TextDisabledWhite: "#ffffff99",
  gray2: "#4F4F4F",
  gray_EC: "#ECECEC",
  //
  IconGray: "#BABABA",
  IconGray2: "#BDBDBD",
  BackgroundGray: "#FAFAFA",
  BorderGray: "#C9C9C9",
  MainDanger: "#D92D20",
};

export default COLORS;
