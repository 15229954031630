/* eslint-disable jsx-a11y/label-has-associated-control */
import Text from "components/general/Text";
import Button from "components/general/Button";
import Icon from "components/general/Icon";
import { useLocation } from "react-router-dom";
import {
  useGetArrivalDataByIdMutation,
  useUpdateArrivalDataMutation,
} from "apis/services/arrival";
import { useEffect, useState } from "react";
import TableMockupProps from "./types";
import styles from "./styles.module.scss";

function TableMockup({
  title,
  btn1Title,
  btn2Title,
  handleCheck,
  onClickBtn1,
  onClickBtn2,
  isBtnDisabled,
  children,
  customBtn,
}: TableMockupProps) {
  const arrowDownIcon = <Icon name="arrow-down" size={24} />;
  const [arrival, setArrival] = useState<any>();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const id = searchParams.get("id");
  const [arrivalData] = useGetArrivalDataByIdMutation();
  const [patchArrival] = useUpdateArrivalDataMutation();

  useEffect(() => {
    if (id) {
      arrivalData(id)
        .unwrap()
        .then((value) => {
          setArrival(value);
        });
    }
  }, [id]);

  const handleCheckboxChange = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setArrival((prevArrival: any) => ({
      ...prevArrival,
      data: {
        is_handled: event.target.checked,
      },
    }));
    try {
      await patchArrival({
        id: arrival?.data?.id,
        data: {
          is_handled: event.target.checked,
        },
      }).unwrap();
    } catch (error) {
      console.error("Error updating arrival data:", error);
    }
  };

  return (
    <div className={`${styles.tableContainer}`}>
      <div className={`${styles.titleContainer}`}>
        <Text>{title}</Text>
        {customBtn && customBtn}
        {btn1Title ? (
          <div className={`${styles.btnContainer}`}>
            {btn2Title ? (
              <Button onClick={onClickBtn2} disabled={isBtnDisabled} isOutline>
                {btn2Title}
              </Button>
            ) : (
              ""
            )}

            <Button
              isOutline={!btn2Title}
              suffix={!btn2Title ? arrowDownIcon : ""}
              onClick={onClickBtn1}
              disabled={isBtnDisabled}
            >
              {btn1Title}
            </Button>
          </div>
        ) : (
          ""
        )}
        {handleCheck && (
          <label>
            <input
              className={styles.inputCheckbox}
              type="checkbox"
              id="checkControl"
              checked={arrival?.data?.is_handled}
              onChange={handleCheckboxChange}
            />
          </label>
        )}
      </div>
      {children}
    </div>
  );
}

export default TableMockup;
