import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import MainTableLayout from "components/wrappers/MainTableLayout";
import ArrivalListTable from "components/ArrivalListTable";
import GuestsTableModal from "components/GuestsTableModal";
import Button from "components/general/Button";
import { useGetArrivalExcelDataMutation } from "apis/services/arrival";

export default function Home() {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [getArrivalExcellData] = useGetArrivalExcelDataMutation();

  const navigate = useNavigate();
  const handleAddNewArrivalNavigation = () => {
    navigate("/Dashboard/add-new-arrival", {
      state: { headerTitle: "Add New Arrival" },
    });
  };

  const exportArrivalData = () => {
    getArrivalExcellData()
      .unwrap()
      .then((res) => {})
      .catch((err) => console.log(err));
  };
  return (
    <>
      <MainTableLayout
        title=""
        hideSearch={false}
        mainBtnText="Add New Arrival"
        mainBtnOnClick={handleAddNewArrivalNavigation}
      >
        <ArrivalListTable setIsGuestsModalVisible={setIsModalVisible} />
      </MainTableLayout>
      <GuestsTableModal
        isVisible={isModalVisible}
        setIsVisible={setIsModalVisible}
      />
    </>
  );
}
