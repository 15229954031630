import Text from "components/general/Text";
import { Form } from "antd";
import TextInput from "components/inputs/TextInput";
import Button from "components/general/Button";
import { Link, useLocation, useNavigate } from "react-router-dom";
import COLORS from "constants/Colors";

import styles from "./styles.module.scss";

export default function ResetPassword() {
  const { state } = useLocation();
  const userEmail = state?.email;

  const navigate = useNavigate();

  return (
    <>
      <Text className={styles.title}>Reset Password</Text>
      <Text className={styles.secondaryTitle}>
        We will send a one-time password to {userEmail?.email}.
      </Text>
      <Form
        className={styles.form}
        autoComplete="off"
        autoCorrect="off"
        onFinish={(val) => {
          // console.log(val);
          navigate("/set-new-password");
        }}
      >
        <Form.Item
          name="code"
          rules={[
            {
              required: true,
              message: "This Field Is Required",
            },
            {
              pattern: /^[0-9]+$/g,
              message: "Enter Only Numbers",
            },
          ]}
        >
          <TextInput i18nLabelKey="VERIFICATION_CODE" />
        </Form.Item>

        <Text className={styles.dontReceive}>
          Don’t receive the OTP Code?{" "}
          <Link to="/forgot-password" style={{ color: COLORS.Primary700 }}>
            <span>Click to Resend</span>
          </Link>
        </Text>

        {/* <Form.Item name="confirmPassword" rules={[{ required: true }]}>
          <TextInput i18nLabelKey="CONFIRMPASSWORD" type="password" />
        </Form.Item> */}

        <Button btnClassName={styles.submitBtn} type="submit">
          Submit
        </Button>
      </Form>
    </>
  );
}
