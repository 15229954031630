import {
  AccommodationTable,
  ArrivalInfoTable,
  FlightTable,
  MembersTable,
  PathwayTable,
} from "components/AddNewArrivalTables";
import AttachmentComponent from "components/AttachmentComponent";
import AddNewArrivalHeader from "components/AddNewArrivalTables/AddNewArrivalHeader";
// import { useArrivalDataMutation } from "apis/services/arrival";
import styles from "./styles.module.scss";

export default function AddNewArrival() {
  // const [arrivalData] = useArrivalDataMutation();

  const saveBtnHandler = () => {
    // arrivalData()
    //   .unwrap()
    //   .then((data) => console.log(data))
    //   .finally(() => {})
    //   .catch((err) => console.log(err));
  };

  const cancelBtnHandler = () => {};

  return (
    <section>
      <div className={styles.container}>
        <AddNewArrivalHeader
          onSave={saveBtnHandler}
          onCancel={cancelBtnHandler}
        />
        <ArrivalInfoTable />
        {/* <MembersTable />
        <FlightTable />
        <AccommodationTable />
        <PathwayTable />
        <AttachmentComponent /> */}
      </div>
    </section>
  );
}
