import { useEffect, useState } from "react";
import { useLocation, useSearchParams } from "react-router-dom";
import {
  useGetCitiesDataMutation,
  useGetNationalityDataMutation,
  useGetCurrenciesDataMutation,
  useGetArrivalDataByIdMutation,
  useGetHotelsMutation,
} from "apis/services/arrival";
import { GetCitiesData } from "apis/types/arrival";
import { Form } from "antd";

export default function GetSelectOptions(item: string) {
  const location = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();

  const id = searchParams.get("id");

  const [getNation] = useGetNationalityDataMutation();
  const [getCities] = useGetCitiesDataMutation();
  const [getCurrencies] = useGetCurrenciesDataMutation();
  const [getArrivalData] = useGetArrivalDataByIdMutation();
  const [getHotels] = useGetHotelsMutation();

  const [NationalityData, setNationalityData] = useState<any[]>([]);
  const [MembersData, setMembersData] = useState<any[]>([]);
  const [flightsData, setFlightsData] = useState<any[]>([]);
  const [CitiesData, setCitiesDataData] = useState<GetCitiesData[]>([]);
  const [currenciesData, setCurrenciesData] = useState<any[]>([]);
  const [allHotels, setAllHotels] = useState<any[]>([]);
  const [form] = Form.useForm();

  const saveBtnHandler = () => {
    getNation()
      .unwrap()
      .then((res) => {
        setNationalityData(res?.results);
      })
      .catch((err) => console.log(err))
      .finally(() => {});
  };

  const GetMembersHandler = () => {
    getArrivalData(id)
      .unwrap()
      .then((res) => {
        setMembersData(res?.data?.member_arrival);
      })
      .catch((err) => console.log(err))
      .finally(() => {});
  };

  const getCurrenciesHandler = () => {
    getCurrencies()
      .unwrap()
      .then((res) => {
        setCurrenciesData(res?.results);
      })
      .catch((err) => console.log(err))
      .finally(() => {});
  };
  const getAllHotelsData = () => {
    getHotels({
      week_days: searchParams.get("week_days") || "",
    })
      .unwrap()
      .then((res) => {
        setAllHotels(res?.results);
      })
      .catch((err) => console.log(err))
      .finally(() => {});
  };

  const GetCitiesHandler = () => {
    getCities()
      .unwrap()
      .then((res) => {
        setCitiesDataData(res?.results);
      })
      .catch((err) => console.log(err))
      .finally(() => {});
  };

  const getFlightsData = () => {
    getArrivalData(id)
      .unwrap()
      .then((res) => {
        setFlightsData(res?.data?.flight_arrival);
      })
      .catch((err) => console.log(err))
      .finally(() => {});
  };

  useEffect(() => {
    if (item === "nationality") {
      saveBtnHandler();
    } else if (item === "member" || item === "accGuestName") {
      GetMembersHandler();
    }

    if (item === "hotelName") {
      getAllHotelsData();
    }
    if (
      item === "flightFrom" ||
      item === "flightTo" ||
      item === "accCity" ||
      item === "city"
    ) {
      GetCitiesHandler();
    }

    if (item === "currency") {
      getCurrenciesHandler();
    }

    if (item === "flightReference") {
      getFlightsData();
    }
  }, [item, searchParams, form]);

  type MyData = {
    [key: string]: unknown; // Index signature
  };

  const selectionOptions: MyData = {
    maturity: [
      { value: "adult", label: "Adult" },
      { value: "child", label: "Child" },
    ],
    gender: [
      { value: "male", label: "Male" },
      { value: "female", label: "Female" },
    ],
    member: [
      ...(MembersData?.map((item) => ({ value: item.id, label: item.name })) ||
        []),
    ],
    currency: [
      ...(currenciesData?.map((item) => ({
        value: item.id,
        label: item.name,
      })) || []),
    ],
    flightFrom: [
      ...(CitiesData?.map((item) => ({
        value: item.id,
        label: item.name,
      })) || []),
    ],

    flightTo: [
      ...(CitiesData?.map((city) => ({ value: city.id, label: city.name })) ||
        []),
    ],
    accByWho: [
      { value: "rotana", label: "Rotana" },
      { value: "nt", label: "nt" },
      { value: "tamira", label: "Tamira" },
    ],
    accGuestName: [
      ...(MembersData?.map((item) => ({ value: item.id, label: item.name })) ||
        []),
    ],
    accCity: [
      ...(CitiesData?.map((item) => ({
        value: item.id,
        label: item.name,
      })) || []),
    ],
    accType: [
      { value: "hotel", label: "Hotel" },
      { value: "deluxe_cruise", label: "Deluxe Cruise" },
      { value: "standard_cruise", label: "Standard Cruise" },
      { value: "dahabiya", label: "Dahabiya" },
    ],
    basis: [
      { value: "bb", label: "BB" },
      { value: "hb", label: "HB" },
      { value: "vp", label: "VP" },
      { value: "ro", label: "RO" },
      { value: "ai", label: "AI" },
    ],
    hotelName: [
      ...(allHotels?.map((hotel) => ({ value: hotel.id, label: hotel.name })) ||
        []),
    ],
    room_type: [
      { value: "single", label: "SGL" },
      { value: "double", label: "DBL" },
      { value: "triple", label: "TPL" },
      { value: "suite", label: "Suite" },
      { value: "family", label: "FAM" },
      { value: "single_od", label: "SGL OD" },
      { value: "single_md", label: "SGL MD" },
      { value: "single_ud", label: "SGL UD" },
      { value: "double_od", label: "DBL OD" },
      { value: "double_md", label: "DBL MD" },
      { value: "double_ud", label: "DBL UD" },
      { value: "triple_od", label: "TPL OD" },
      { value: "triple_md", label: "TPL MD" },
      { value: "triple_ud", label: "TPL UD" },
      { value: "suite_od", label: "Suite OD" },
      { value: "suite_md", label: "Suite MD" },
      { value: "suite_ud", label: "Suite UD" },
      { value: "family_od", label: "FAM OD" },
      { value: "family_md", label: "FAM MD" },
      { value: "family_ud", label: "FAM UD" },
    ],
    payment: [
      { value: "paid", label: "Paid" },
      { value: "not_paid", label: "Not Paid" },
      { value: "voucher", label: "Voucher" },
    ],
    per: [
      { value: "email", label: "Email" },
      { value: "contract", label: "Contract" },
      { value: "negotiation", label: "Negotiation " },
    ],
    status: [
      { value: "booked", label: "Booked" },
      { value: "waiting", label: "Waiting" },
    ],
    pathPathway: [
      { value: "trip", label: "trip" },
      { value: "flight", label: "flight" },
    ],
    trip_type: [
      { value: "optional", label: "Optional" },
      { value: "ausflugspaket", label: "Ausflugspaket" },
    ],
    flightReference: [
      ...(flightsData?.map((item) => ({
        value: item.id,
        label: item.flight_reference,
      })) || []),
    ],
    pathwayType: [
      { value: "trip", label: "Trip" },
      { value: "flight", label: "Flight" },
    ],
    city: [
      ...(CitiesData?.map((city) => ({
        value: city.id,
        label: city.name,
      })) || []),
    ],
    pickUp: [
      { value: 1, label: "Hotel One" },
      { value: 2, label: "Hotel Two" },
    ],
    guideInVisits: [
      { value: "Mohamed Khaled", label: "Mohamed Khaled" },
      { value: "Ahmed Mohamed", label: "Ahmed Mohamed" },
    ],
    leaderName: [
      { value: 3, label: "Ali Taha" },
      { value: 2, label: "Mohamed Sayed" },
    ],
    driverName: [
      { value: "Abdalrhman Hesham", label: "Abdalrhman Hesham" },
      { value: "Maher Mohamed", label: "Maher Mohamed" },
    ],
  };

  return selectionOptions[item];
}
