const selectArr = [
  "maturity",
  "gender",
  "packageType",
  "member",
  "flightFrom",
  "flightTo",
  "flightReference",
  "pathway",
  "city",
  "pickUp",
  "guideInVisits",
  "leaderName",
  "driverName",
  "accByWho",
  "accGuestName",
  "accCity",
  "accType",
  "hotelName",
  "room_type",
  "basis",
  "payment",
  "per",
  "status",
  "currency",
  // PATHWAY
  "pathwayType",
  "flight",
  "flightNum",
  "pathPathway",
  "pathTicketNum",
  "pathCity",
  "pathLeaderName",
  "pathLeaderFees",
  "pathDriverName",
  "toFrom",
  "trip_type",
];

const dateArr = ["date", "check_in", "check_out", "flightDate", "due_date"];

const timeArr = [
  "time",
  "departure_time",
  "arrival_time",
  "flightTime",
  "groupTime",
  "departureTime",
  "arrivalTime",
];

const textArr = ["nationality"];

function isSelect(query: string): boolean {
  return selectArr.includes(query);
}
function isDate(query: string): boolean {
  return dateArr.includes(query);
}
function isTime(query: string): boolean {
  return timeArr.includes(query);
}
function isText(query: string): boolean {
  return textArr.includes(query);
}
function isFile(query: string): boolean {
  return query === "attachment";
}

function getTableCellInputType(cellIndex: string): string {
  let inputType = "text";
  if (isText(cellIndex)) inputType = "text";
  if (isSelect(cellIndex)) inputType = "select";
  if (isDate(cellIndex)) inputType = "dateInput";
  if (isTime(cellIndex)) inputType = "timeInput";
  if (isFile(cellIndex)) inputType = "fileInput";

  return inputType;
}
export default getTableCellInputType;
