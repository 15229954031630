import { Modal } from "antd";
import styles from "./styles.module.scss";
import { ModalProps } from "./types";

export default function ModalComponent({
  children,
  isVisible,
  setIsVisible,
  title,
  centered,
  width = 880,
  modalClassName,
}: ModalProps) {
  const handleOk = () => setIsVisible(false);
  const handleCancel = () => setIsVisible(false);

  return (
    <Modal
      title={title}
      open={isVisible}
      onOk={handleOk}
      onCancel={handleCancel}
      centered={centered}
      footer={null}
      width={width}
      className={`${styles.modalContainer} ${modalClassName}`}
    >
      <div className={styles.containerStyle}>{children}</div>
    </Modal>
  );
}
