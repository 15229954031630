import { useEffect, useState } from "react";
import TableForm from "components/TableForm";
import { useSearchParams } from "react-router-dom";
import {
  useGetAccommodationDataQuery,
  useGetArrivalDataByIdMutation,
  useGetSingleAccommodationMutation,
  useLazyGetAccommodationDataQuery,
} from "apis/services/arrival";
import TableMockup from "./TableMockup";
import styles from "./styles.module.scss";
import columns from "./Columns/Accommodation";
import prepareAccommodationData from "./Utils/prepareAccommodationData";

const getEmptyObj = (key: number) => {
  return {
    key: key.toString(),
    num: key <= 9 ? `0${key}` : key.toString(),
    accByWho: ``,
    reservationNum: ``,
    accGuestName: "",
    accCity: "",
    accType: "",
    hotelName: "",
    accToursDays: "",
    accNight: "",
    roomNum: "",
    accRoomType: "",
    accBasis: "",
    checkIn: "",
    checkOut: "",
    accPayment: "",
    due_date: "",
    costNight: "",
    accPer: "",
    totalCost: "",
    accStatus: "",
  };
};

export default function Accommodation({ id }: any) {
  const [data, setData] = useState<any>([]);
  const [editingKey, setEditingKey] = useState("");
  const [column, setColumn] = useState<any>(columns);
  const [accData, setAccData] = useState<any>();
  const [getArrivalData, { isLoading: isGetArrivalDataLoading }] =
    useGetArrivalDataByIdMutation();
  const [paginationCount, setPaginationCount] = useState(0);

  const [getAccommodationData] = useLazyGetAccommodationDataQuery();
  const urlId = id;
  const [searchParams] = useSearchParams();

  const fetchAccommodationData = async (params: any) => {
    try {
      const arrivalData = await getArrivalData(id).unwrap();

      getAccommodationData(params)
        .unwrap()
        .then((accoData: any) => {
          setData(prepareAccommodationData(accoData.results));
          setPaginationCount(accoData.count);
        });
    } catch (error) {
      console.error("Error fetching members data:", error);
    }
  };

  useEffect(() => {
    fetchAccommodationData({
      page: searchParams.get("accommodationTable") || "1",
      page_size: 5,
      arrival: id,
    });
  }, [id, searchParams.get("accommodationTable")]);

  useEffect(() => {
    const payment = searchParams.get("payment");
    const isPaymentNotPaid = payment === "not_paid";

    const duoDateColumnIndex = column.findIndex(
      (col: any) => col.dataIndex === "due_date"
    );

    const newDueColumnObject = {
      ...column[duoDateColumnIndex],
      editable: isPaymentNotPaid,
    };

    column.splice(duoDateColumnIndex, 1, newDueColumnObject);

    setColumn([...column]);
  }, [searchParams]);

  const handleAddNewRow = () => {
    setData((prev: any) => [getEmptyObj(prev.length), ...prev]);
    setEditingKey(`${data.length}`);
  };

  return (
    <div className={`${styles.accommodationTableContainer}`}>
      <TableMockup
        title="Accommodation"
        btn1Title="Add New Accommodation"
        onClickBtn1={handleAddNewRow}
        isBtnDisabled={!!editingKey || isGetArrivalDataLoading}
        handleCheck=""
      >
        <TableForm
          data={data}
          setData={setData}
          columns={column}
          editingKey={editingKey}
          setEditingKey={setEditingKey}
          tourPathMethod={() => {}}
          title="accommodation"
          urlId={urlId}
          fetchData={fetchAccommodationData}
          setIsLoading={isGetArrivalDataLoading}
          paginationCount={paginationCount}
          paginationName="accommodationTable"
        />
      </TableMockup>
    </div>
  );
}
